.m_comment-section {
  grid-column: span 2;
  margin-block: 2rem;
  padding-top: 2rem;
  border-top: 1px solid currentColor;

  @include media-breakpoint-up($bp-grid-2fr) {
    grid-column: span 3;
  }

  @include media-breakpoint-up($bp-grid-4fr) {
    grid-column: span 2;
    margin-top: -5.3rem;
    margin-left: 3rem;
    padding-left: 3rem;
    border-left: currentColor solid 1px;
    border-top: 0px solid currentColor;
    padding-top: 0.5rem;
  }

  @include media-breakpoint-up($bp-grid-6fr) {
    grid-column: span 3;
  }

  &-title {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 2.5rem;
  }

  &-title-icon {
    height: 4rem;
    width: auto;
    fill: currentColor;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.m_comment-item {
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1.2rem;

  &-img {
    object-fit: cover;
    border-radius: 1rem;
    aspect-ratio: 1/1;
    width: 6rem;
    flex-shrink: 0;
    height: auto;
  }

  &-content {
    overflow: hidden;
    &-headline {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.5rem;
      padding-bottom: 0.4rem;

      &-icon {
        height: 1.4rem;
        width: auto;
        fill: currentColor;
      }

      &-pseudo {
        font-weight: bold;
      }

      &-date {
        opacity: 0.6;
      }
    }

    &-resume {
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
      line-height: 1.25;
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                    Hover                                   */
/* -------------------------------------------------------------------------- */

.m_comment-item:hover,
.m_comment-item:focus {
  .m_comment-item-content-headline-icon {
    fill: var(--site-color);
  }

  .m_comment-item-content-headline-pseudo,
  .m_comment-item-content-headline-date {
    color: var(--site-color);
  }
}
