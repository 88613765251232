.menu-haut {
  display: flex;
  margin:0;
  padding: 0;
  list-style:none;
  align-items: center;

  a {
    text-decoration: none;
  }

  .menu-haut-0-item {
    margin:0  0 0 1.35rem;
  }

  li:first-child {
    margin-left: 0;
  }

  a:not(.btn){
    span.menu-haut-texte{
      border-bottom: 1px dotted;
    }
    &:focus span.menu-haut-texte, 
    &:hover span.menu-haut-texte{
      border-bottom-color:transparent;
    }
    
  }
  
}