/* -------------------------------------------------------------------------- */
/*                                    Film                                    */
/* -------------------------------------------------------------------------- */
.film-liste-item{
  ul {
      margin: .5rem 0;
      padding: 0;
      list-style: none;
      font-weight: bold;
  }
  li {
      padding: 0;
      margin:0;
  }
}

.bientot .film-liste-image ,
.bientot .block-image-wrapper  {
  transform:translateZ(0);
  overflow: hidden;

  &::before{
    z-index: 10;
    content: 'Bientôt';
    display: block;
    background-color: $cinema;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    padding: .25rem 3rem;
    position: absolute;
    transform: translateX(-2.5rem) translateY(1.4rem) rotate(-45deg) ;
    transform-origin: 50% 50%;
    font-size: .7rem;
  }
}