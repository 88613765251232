#website-header:not(.is-shadowed) .sous-menu{ 
  box-shadow:  0 9px 20px -5px rgba(0,0,0,.2);
  background:rgba(255,255,255,1);
}

.sous-menu {
  position: absolute;
  padding: 0 1.4rem;
  box-shadow:  0 0 0 0 rgba(0,0,0,0);
  transition:box-shadow .2s;
  background-color: rgba(255,255,255,0);
  font-size: 1.1rem;
  border-radius: .5rem;
  z-index: 32;
  display: flex;
  align-items: center;
  line-height: 1.1;
  white-space: nowrap;

  a {
    display: block;
    text-decoration: none;
    padding:.8rem 0;
    margin-left:1rem ;
    position: relative;

    &::first-letter {
      text-transform: uppercase;
    }

    &:first-child {
      margin-left: 0;
    }

    &::before {
      content: '';
      background:currentColor;
      position: absolute;
      left: 0;
      bottom:0;
      height: 0;
      width: 100%;
      transition:.2s;
      background: $gray-400;

    }

    &:hover,
    &:focus{
      &::before{
        background: $gray-400;
        height:.18rem;
      }
    }

    &.active {
      &::before{
        height:.18rem;
        background:currentColor;
      }
    }
  

  }
  
  
}


.sous-menu[data-popper-reference-hidden] {
  visibility: hidden;
  pointer-events: none;
}