/* -------------------------------------------------------------------------- */
/*                                   LAYOUT                                   */
/* -------------------------------------------------------------------------- */
.node-film-grid {
  margin-bottom: s(8);

  @include media-breakpoint-up(lg){
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 0 calc(var(--gutter-x) *2);
  }
}

.node-film-cell-main {
  margin-bottom: s(5);
}

.node-film-cell-aside {
  margin-bottom: s(5);
  
  @include media-breakpoint-up(lg){
    margin-top: 5rem;
  }
}


/* -------------------------------------------------------------------------- */
/*                                node-film-nav                               */
/* -------------------------------------------------------------------------- */
.node-film-nav {
  display: flex;
  align-items: center;
  margin-bottom: s(2);
  font-size: 1.6rem;
  
  a {
    padding: 0 .5em;
    color: $gray-400;
    transition: .2s;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.is-active {
      color: $body-color;
    }
  }
  
  .btn-retour {
    border-right: 1px solid $gray-400;
  }
}


/* -------------------------------------------------------------------------- */
/*                               node-film-video                              */
/* -------------------------------------------------------------------------- */
.node-film-video {
  margin-bottom: s(4);

  // iframe,
  // video {
  //   width: 100%;
  // }
}


/* -------------------------------------------------------------------------- */
/*                              node-film-header                              */
/* -------------------------------------------------------------------------- */
.node-film-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: s(4);
}

.node-film-header-content {
  padding-right: s(3);
}

.node-film-titre {
  font-weight: $font-weight-bolder;
}

// Header infos
.node-film-header-infos-item {
  // @extend .element-separe-dot-light;

}

// btn-partage
.node-film-header .btn-partage {
  &:after {
    display: none;
  }
}


/* -------------------------------------------------------------------------- */
/*                              node-film-content                             */
/* -------------------------------------------------------------------------- */
.node-film-content {

  @include media-breakpoint-up(xl){
    display: grid;
    grid-template-columns: 5fr minmax(0, 2fr);
    grid-gap: 0 1.5rem;
  }
}

.node-film-content-main {
  max-width: 45rem;
}

.node-film-texte  {
  position: relative;
  @include margin-bottom(1.5rem);
  overflow: hidden;
  transition: max-height .25s ease-in-out;
  max-height: calc(#{$line-height-base} * #{$font-size-base} * 10 );
}

.collapsed-text {
  &:after{
    content: "";
    height: calc(#{$line-height-base} * #{$font-size-base} * 2 );
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    visibility: visible;
  } 
}

.visible-text {
  max-height: max-content;
  &:after {
    height: 0;
  }
}

.read-more-less {
  font-weight: $font-weight-bolder;
  cursor: pointer;
  margin-bottom: 1.25rem;
}

// INFOS
.node-film-content-infos-item {
  display: flex;
  align-items: flex-start;
  line-height: 1.2;
  margin-bottom: s(2);
 
  .node-film-content-infos-item-icone {
    flex-shrink: 0;
    width: 2rem;
    margin-right: s(2);
    text-align: center;
  }
}

.node-film-content-infos-item-icone-diaz {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.2rem;
  background-color: $body-color;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  border-radius: 3px;
  text-transform: uppercase;
}


.node-film-content-infos a {
  color: inherit;
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
    color: inherit;
  }
}

/* -------------------------------------------------------------------------- */
/*                           node-film-strate-widget                          */
/* -------------------------------------------------------------------------- */
.node-film-strate-widget {
  @include margin-top(4.5rem);
}

.node-film-strate-widget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
  border-bottom: 1px solid $nav-tabs-border-color;

  .nav-tabs {
    flex-grow: 1;
    border-bottom: 0;
  }

  > * {
    margin-bottom: -1px;
  }
}

.nav-tabs {
  
  .nav-link {
    border-width: 0;
    border-bottom-width: 4px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.node-film-strate-widget #comments { 
  div:last-of-type {
    border: none;
  }
}

.node-film-strate-widget .comment {
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid $light;

  .submitted {
    font-size: 1.15rem;
    margin-bottom: .5rem;
    color: $gray-500;
  }
  .content {
    color: $black;
  }

  .user-picture {
    display: none;
  }

  .links.inline {
    position: absolute;
    top: 0;
    right: 0;
  }

  .new {
    display: none;
  }

}

/* -------------------------------------------------------------------------- */
/*                                 Titre Trait                                */
/* -------------------------------------------------------------------------- */
.titre-trait {
  text-transform: uppercase;
  color: $gray-600;
  font-size: .85rem;
  margin-bottom: .75rem;
  display: flex;
  align-items: center;

  &:after {
    content:"";
    display: block;
    margin-left:.8rem;
    height: 1px;
    background:$gray-400;
    flex-grow: 1;
  }

}

/* -------------------------------------------------------------------------- */
/*                                   Partage                                  */
/* -------------------------------------------------------------------------- */
.dropdown-partage {
  width: 310px;

  input[type=text]{
    text-overflow: ellipsis;
  }
}

/* -------------------------------------------------------------------------- */
/*                              BLOCK PUBLICATION                             */
/* -------------------------------------------------------------------------- */
.block-memoire-publication {
  @include min-ratio('16x9');
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: flex-end;
  @include margin-bottom(2rem);
  background-color: $gray-700;

  figure {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    filter:grayscale(100) contrast(1.4) contrast(.5);
    transition:.2s;

    img {
      object-fit:cover;
    }
  }

  .block-memoire-publication-titre {
    @include font-size($h4-font-size);
    font-weight: 600;
    z-index: 2;
    color:#fff;
    margin:1rem;
    line-height: $line-height-sm;
  } 

  &:focus,
  &:hover {
    figure {
      filter:grayscale(0) ;
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                           ADD FILM TO PRO PROJECT                          */
/* -------------------------------------------------------------------------- */


.add-film-to-project-form {
  padding: 1.5rem;
  min-width: 90vw;
  box-shadow: $box-shadow-lg;
  @include media-breakpoint-up(lg){
    padding: 3rem;
    min-width: 40vw;
  }
}

.node-type-memoire-film .fancybox-bg {
  background: rgb(225, 225, 225);
  opacity: 0.85;
}