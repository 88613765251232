

.block-memoire-film-comment, .liste-memoire-film-comment {
  position: absolute;
  bottom:1rem;
  left:1rem;
  color:#fff;
  font-size: .8rem;
  z-index: 2;
}


.block-memoire-film-date, .liste-memoire-film-date {
  font-weight: 600;
  text-transform: none;
}

/* -------------------------------------------------------------------------- */
/*                           BLOCK ITEMS xs en ligne                          */
/* -------------------------------------------------------------------------- */
.liste-films-xs {
  .node-liste {
      display: block;
  }
  @include block-item-xs;
  
  @include media-breakpoint-up(md){
    .block-item .block-titre {
      font-size: 1.25rem;
    }
  }
}