/* -------------------------------------------------------------------------- */
/*                               STRATE NOUVEAUX                              */
/* -------------------------------------------------------------------------- */

#strate-nouveaux {
  
  .node-liste {
    @include media-breakpoint-up($bp-grid-2fr){
      .block-item:nth-child(5){
        display: none;
      }      
    }
    @include media-breakpoint-up($bp-grid-3fr){
      .block-item:nth-child(5){
        display: block;
      }            
    }
    @include media-breakpoint-up($bp-grid-4fr){
      .block-item:nth-child(5){
        display: none;
      }
    }
    @include media-breakpoint-up($bp-grid-6fr){
      .block-item:nth-child(5){
        display: block;
      }      
    }
  }
} 