.mini-site-livre {
  --site-color: #{$livre};
}
.mini-site-upopi {
  --site-color: #{$upopi};
}
.mini-site-cinemobile {
  --site-color: #{$cinema};
}
.mini-site-memoire {
  --site-color: #{$patrimoine};
}
.mini-site-animationfr,
.mini-site-animationen {
  --site-color: #{$animation};
}

.pole-ciclic {
  --site-color: #{$body-color};
}
.pole-cinema {
  --site-color: #{$cinema};
}
.pole-education {
  --site-color: #{$education};
}
.pole-patrimoine {
  --site-color: #{$patrimoine};
}
.pole-livre {
  --site-color: #{$livre};
}
.pole-upopi {
  --site-color: #{$upopi};
}
.pole-cinemobile {
  --site-color: #{$cinema};
}
.pole-animation {
  --site-color: #{$animation};
}
[class*="pole-"][class*="-info"]:not([class*="infos"]) {
  --site-color: #{$gray-500};
}

// APPLICATION CODE COULEUR
.couleur-fond {
  background-color: var(--site-color);
}

.couleur-texte,
.chapo,
.accroche {
  color: var(--site-color);
}

.hover-couleur-texte:hover,
.hover-couleur-texte:focus {
  color: var(--site-color);
}

.active-couleur-texte.active {
  color: var(--site-color);
}

.hover-not-btn-couleur-texte:not(.btn):hover,
.hover-not-btn-couleur-texte:not(.btn):focus {
  color: var(--site-color);
}

.active-not-btn-couleur-texte:not(.btn).active {
  color: var(--site-color);
}

/* -------------------------------------------------------------------------- */
/*                              FILTRES BICHROMIE                             */
/* -------------------------------------------------------------------------- */
.fx-bichro {
  --filterSvg: url("#bichro-ciclic");
  img {
    filter: var(--filterSvg);
  }
}

.pole-ciclic {
  --filterSvg: url("#bichro-ciclic");
}

.pole-cinema {
  --filterSvg: url("#bichro-cinema");
}

.pole-patrimoine {
  --filterSvg: url("#bichro-patrimoine");
}

.pole-livre {
  --filterSvg: url("#bichro-livre");
}

.pole-education {
  --filterSvg: url("#bichro-education");
}

.pole-animation {
  --filterSvg: url("#bichro-animation");
}

.pole-cinemobile {
  --filterSvg: url("#bichro-cinemobile");
}

.pole-upopi {
  --filterSvg: url("#bichro-upopi");
}

.pole-animation {
  --filterSvg: url("#bichro-animation");
}

/* -------------------------------------------------------------------------- */
/*                              BICHROMIE MEMOIRE                             */
/* -------------------------------------------------------------------------- */
[data-random-bichro="random-bichro-yellow"] {
  --filterSvg: url("#random-bichro-yellow");
}
[data-random-bichro="random-bichro-blue"] {
  --filterSvg: url("#random-bichro-blue");
}
[data-random-bichro="random-bichro-peach"] {
  --filterSvg: url("#random-bichro-peach");
}
[data-random-bichro="random-bichro-mauve"] {
  --filterSvg: url("#random-bichro-mauve");
}
[data-random-bichro="random-bichro-orange"] {
  --filterSvg: url("#random-bichro-orange");
}
[data-random-bichro="random-bichro-purple"] {
  --filterSvg: url("#random-bichro-purple");
}
[data-random-bichro="random-bichro-green"] {
  --filterSvg: url("#random-bichro-green");
}
