/* -------------------------------------------------------------------------- */
/*                               STRATE ARCHIVES                              */
/* -------------------------------------------------------------------------- */

#strate-archives .strate-titre {
  .memoire-home-archives-nav {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}

#strate-archives .node-liste-container {
  grid-column: 1/-1;
  display: block;
  overflow: visible;
  width: 100%;
  margin-right: var(--container-gutter-x-neg);
  @include hors-container("r");
  .node-liste {
    display: block;
  }
  .swiper-memoire-liste-films-archives {
    overflow: visible;
    // margin-right: 0 ;
  }
  .swiper-wrapper {
    width: 100%;
  }
}
