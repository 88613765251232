/* -------------------------------------------------------------------------- */
/*                                 BLOCK ACTUS                                */
/* -------------------------------------------------------------------------- */

#strate-actus {
  @include media-breakpoint-up($bp-grid-4fr){
    .block-item:nth-child(4){
      display: none;
    }
    .block-agenda-widget{
      grid-column: span 1;
    }
  }
  @include media-breakpoint-up($bp-grid-6fr){
    .block-item:nth-child(4){
      display: block;
    }
    .block-agenda-widget{
      grid-column: span 2;
    }    
  }
} 