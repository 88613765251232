.comment-form {
  margin-bottom: 5rem;

  label {
    margin-bottom: .25rem;
    font-weight: $font-weight-bold;
    font-size: 1.125rem;
  }
  fieldset.collapsible{
    height: auto !important;
  } 
}


// Cache le sujet dans le formulaire et en rendu
.comment-wrapper {
  h3.title,
  .form-item-subject{
    display: none;
  }
}

// MASQUE titre "commentaires"
.comment-wrapper h2 {
  display: none;
}

.comment {
  @include margin-bottom(1.5rem);
  overflow-wrap: break-word;
  word-break: break-word;
}



// ligne auteur
.submitted {
  margin-bottom: .15rem;
  color: $text-muted;
  .username {
    font-weight: bold;
    color: $body-color;
    text-decoration: none;
    &::after{
      content:' • ';
      color: $text-muted;
      font-weight: normal;
    }
  }
}


.comment-wrapper .links.inline {
    display: flex;
    flex-wrap: wrap;
    margin:0 0 0;
    list-style:none;
    padding: 0;
    font-size: .8rem;
    text-transform: capitalize;

  a {
    border-radius: 50rem;
    padding: 2px 8px;
    margin-right: 5px;
    border:1px solid;
    text-decoration: none;
    display: inline-block;
  }

  .comment-delete a{
    color: $red;
  }
  .comment-edit a{
    color: $warning;
  }
  .comment-reply{
    display: none;
  }
}

.comment + h2 + .comment-form {
  margin-top:2rem;
  padding-top: 2rem;
  border-top:2px dashed $gray-300;
}


// FORMULAIRE
.comment-form {
  .username {
    font-weight: bold;
    text-decoration: none;
  }
  [id*=edit-preview]{
    display: none;
  }
  
}


.comment-form label {
  margin-bottom: 0;
}

@media screen and (min-width:992px) {
  .comment-form > div {
    display: grid;
    grid-gap: 0 2rem ;
    grid-template-columns: 1fr 1fr;

    label {
      margin-bottom: 0;
    }
    .field-type-text-long,
    .field-type-list-boolean,
    .form-actions {
      grid-column: span 2;
    }
  }


  .field-name-field-is-error {
    
    label:before{
      content:'';
      background: url('data:image/svg+xml; utf8, <svg class="svg-inline--fa fa-flag fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="flag" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M349.565 98.783C295.978 98.783 251.721 64 184.348 64c-24.955 0-47.309 4.384-68.045 12.013a55.947 55.947 0 0 0 3.586-23.562C118.117 24.015 94.806 1.206 66.338.048 34.345-1.254 8 24.296 8 56c0 19.026 9.497 35.825 24 45.945V488c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24v-94.4c28.311-12.064 63.582-22.122 114.435-22.122 53.588 0 97.844 34.783 165.217 34.783 48.169 0 86.667-16.294 122.505-40.858C506.84 359.452 512 349.571 512 339.045v-243.1c0-23.393-24.269-38.87-45.485-29.016-34.338 15.948-76.454 31.854-116.95 31.854z"></path></svg>');
      height: 1em;
      width: .8em;
      background-position: center center ;
      background-repeat: no-repeat;
      background-size:contain;
      margin-right:.25rem;
      display:inline-block;
    }

    .description {
      margin-left: 1.25em;
    }
  } 
}
