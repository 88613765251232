/*------------------------------------------------
:: BLOCK dossier
--------------------------------------------------*/
.block-type-dossier {
  grid-column: span 3;
  overflow: hidden;
  position: relative;
  @include min-ratio('3x2');
  background:var(--site-color);

  img {
    position:absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.block-type-dossier a{
    display: block;
    color: #fff;
    &:focus img,
    &:hover img{
      filter: grayscale(100)   contrast(1.9) brightness(.6) contrast(.6);
    }
}
.block-type-dossier figure img{
    width: 100%;
}
.block-type-dossier a:hover figure img{
    opacity: 1 !important;
}

.block-dossier-texte{
    position: absolute;
    padding:var(--gutter-x);
    bottom: 0;
    left: 0;
    z-index: 2;
}

.block-dossier-titre {
  font-weight: bold;
  max-width: 30ch;
}

.block-dossier-tag {
  top: -.2em;
  left:var(--gutter-x);
  position: absolute;
}

.block-dossier-sous-titre {
  line-height: $line-height-sm;
  font-size: 1.2rem;
  max-width: 50ch;
}