/* -------------------------------------------------------------------------- */
/*                               HOME LINK BLOCK                              */
/* -------------------------------------------------------------------------- */

.home-link-block {
  position: relative;
  display: flex;
  align-items: center;
  grid-column: 1/-1;
  margin-top: calc(var(--gutter-y) * 2);
  border-radius: 10px;
  overflow: hidden;
  @include media-breakpoint-up($bp-grid-6fr){
    margin-top: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    grid-column: 6/ span 1;
    border-radius: 0;
    overflow: visible;
  }
}
.home-link-block .home-link-block-bg {
  background-color: var(--site-color);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    object-position: 50% 50%;
  }
  @include media-breakpoint-up($bp-grid-6fr){
    @include hors-container("r");
    margin-left: calc(var(--gutter-x) * -2);
    z-index: -1;
  }
}

.home-link-block-content {
  text-align: center;
  max-width: 50ch;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: calc(var(--gutter-y) * 2) var(--gutter-x);
  @include media-breakpoint-up($bp-grid-4fr){
    padding: calc(var(--gutter-y) * 4) var(--gutter-x);
  }
  @include media-breakpoint-up($bp-grid-6fr){
    text-align: left;
    padding:  0 0 0 var(--gutter-x);
  }
}