/* -------------------------------------------------------------------------- */
/*                                 Holy Grail                                 */
/* -------------------------------------------------------------------------- */
html , 
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;

  >pre {
    flex-shrink: 0;
  }
}



.website-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .main-content-container {
    flex-grow: 1;
  }
}
