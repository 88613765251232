/* -------------------------------------------------------------------------- */
/*                                    CARTE                                   */
/* -------------------------------------------------------------------------- */

.memoire-liste-films .vue-carte {
  display:block;
}

.memoire-liste-films .vue-carte {
  .block-item .block-texte-wrapper {
    @include media-breakpoint-down(lg){
      padding: 1rem;
    }
  }
}

article:not(.node-film) {
  .google-maps-container{
    position: sticky !important;
    top:0;
    max-height: none !important;
    height: 100vh !important;
    @include hors-container("r");
  }
}

/* -------------------------------------------------------------------------- */
/*                            RECHERCHE ET FILTRES                            */
/* -------------------------------------------------------------------------- */


.memoire-liste-films .liste-filtres {
  // margin: 4rem 0;
  // margin-top: 2rem;
}

.memoire-liste-films .filtres-wrapper {
  display: flex;
  flex-direction: column;
  
  // margin-bottom: 2rem;
  @include media-breakpoint-up(lg){
    flex-direction: row;
    align-items: center;
    grid-template-columns: 1fr 1fr;
  }
}

// formulaire de recherche textuelle
.memoire-liste-films .memoire-recherche-text {
  position: relative;
  min-width: 50%;
  // margin-bottom: 1rem;
  
  .form-item {
    margin-bottom: 0;
    width: 100%;
  }
  
  input[type=text] {
    border: 0;
    margin-top: 0;
    padding: 1rem 4rem 1rem 2rem;
  }
  
  .button-recherche-global {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    padding: 0;
    margin-right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: none;
  }  
}

//formulaire de filtrage par date


.memoire-liste-films {

  @include memoire-slider-date;

}

// filtres critères

.memoire-liste-films .form-item .form-type-checkbox {
  label {
    cursor: pointer;
  }
  margin: .5rem 0;
  color: $text-muted;
  font-size: 1.125rem;
  position: relative;
  &:hover {
    color: var(--site-color);
  }
}

.memoire-liste-films input[type=checkbox] {
  display: none;
  &:checked + label{
    color: var(--site-color);
  }
  & + label:after {
    content: "✕";
    display: none;
    position: absolute;
    top: 0;
    right: 0;
  }
  &:checked + label:after {
    display: block;
  }
}

.memoire-liste-films .filtres-counter {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--site-color);
  color: white;
  transform: translateY(50%) translateX(-50%);
}

/* -------------------------------------------------------------------------- */
/*                              RECHERCHE AVANCEE                             */
/* -------------------------------------------------------------------------- */

#filtres_avances {
  .form-actions {
    margin: 0 -0.25rem;
    margin-top: 1.5rem;
    display: flex;
    * {
      margin: 0 0.25rem;
    }
  }
}
.group_selecteurs_av {
  position: relative;
  &:not(:last-child):after {
    content: "et";
    position: absolute;
    padding: .25rem .5rem;
    background-color: $danger;
    color: white;
    text-transform: uppercase;
    border-radius: .25rem;
    font-size: .75rem;
    bottom: -.75rem;
    left: .5rem;
  }
  display: flex;
  align-items: center;
  padding: .75rem 0;
  .filtres_av-actions {
    display: flex;
    flex-direction: row-reverse;
    .btn-outline-danger:hover {
      color: white !important;
    }
    *{
      margin: 0 .5rem;
    }
  }
  .filtres_av-selectors {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    .ss-single-selected, .ss-multi-selected {
      min-height: 2.5rem;
      background-color: $light;
      border: none;
      font-size: 1rem;
      .ss-disabled {
        color: $primary !important;
      }
    }
    .ss-single-selected, .ss-multi-selected {
      padding: .375rem .75rem;
    }
    .ss-multi-selected {
      align-items: center;
      .ss-values {
        flex-wrap: nowrap;
        padding-right: .5rem;
      }
      .ss-add {
        margin: 0px 12px 0px 5px;
      }
      .ss-value {
        background-color: white;
        color: $dark;
        .ss-value-text {
          white-space: nowrap;
        }
      }
    }
    .ss-main {
      height: 100%;
    }
    .ss-content {
      border: none;
      box-shadow: $box-shadow;
      border-radius: .375rem;
      top: 125%;
      .ss-search {
        input {
          border-bottom: 1px solid $light;
        }
      } 
      .ss-search:not(.ss-hide), .ss-option {
        padding: .5rem .75rem;
      }
      .ss-option:hover {
        background-color: $success;
      }
      .ssks-ss-separator {
        border-bottom: 1px solid $light;
      }
    }
  }
  &:not(:last-child){
    border-bottom: 2px dotted $light;
  }
  .form-item {
    margin-bottom: 0;
    min-width: 15rem;
    margin: .375rem .75rem .375rem 0;
  }
}


@keyframes spinner {
  to {transform: rotate(360deg);}
}
 
.ss-spinner {
  position: relative;
  height: 20px;
  width: 100%;
  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: var(--site-color);
    animation: spinner .6s linear infinite;
  }
}