.menu-outils {
  
  @include media-breakpoint-down(lg){
    display:none;
  }

  position: fixed;
  left:.35rem;
  @include media-breakpoint-up(xl){
    left:.75rem;
  }
  bottom:1rem;
  z-index: $z-index-outils;
  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ul, 
  li {
    padding: 0;
    margin:0;
    list-style: none;
  }

  a {
    background-color: $gray-200;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: s(1);
    width: 2.4rem;
    height: 2.4rem;
    font-size: 1.15rem;

    &:focus,
    &:hover,
    &:active{
      background-color: $gray-400;
    }

    &.active {
      background-color: $black;
      color:#fff;
      box-shadow: 0 0 0 2px #fff;
    }
  }
}
// CACHE TEXTE
.menu-outils-texte {
  display: none;
}

// ACTUALITES plus grand et bleu
.outil-actualites-item {
  order:999;

  a {
    margin-top: s(2);
    width: 3.4rem;
    height: 3.4rem;
    font-size: 1.7rem;
    background-color: $blue;
    color: #fff;

    &:focus,
    &:hover,
    &:active{
      background-color: darken($blue, 10%);
    }
  }
}

.outils-ressources-item {
  order:990;  
}

/* -------------------------------------------------------------------------- */
/*                           append svg ili (ciclic)                          */
/* -------------------------------------------------------------------------- */
.svg-ili {
  fill : currentColor;
  width: 1.3em;
  height: 1.3em;
  object-fit: contain;
}