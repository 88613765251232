/* -------------------------------------------------------------------------- */
/*                               RECHERCHE HOME                               */
/* -------------------------------------------------------------------------- */

.recherche-home {
}

// Outer Layout
.recherche-home form {
  grid-column: 1/-1;
  margin: calc(var(--gutter-x) * 2) 0;

  @include media-breakpoint-up($bp-grid-6fr){
    grid-column: 2/6;
  }
}

// Inner Layout
.recherche-home form {
  display: flex;
  align-items: center;
  &:after, &:before {
    content: " ";
    display: none;
    width: 4rem;
    height: 2px;
    background-color: $light;
    @include media-breakpoint-up($bp-grid-4fr){
      display: block;
    }
  }
}

.recherche-home-bloc {
  position: relative;
  flex-grow: 2;
  @include media-breakpoint-up($bp-grid-4fr){
    margin: 0 1rem;
  }
}

.recherche-home-input {
  padding: 0.5rem 4rem 0.5rem 1rem;
  @include media-breakpoint-up($bp-grid-4fr){
    padding: 1rem 4rem 1rem 2rem;
  }
}

.recherche-home-btn {
  all: unset;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  @include media-breakpoint-up($bp-grid-4fr){
    padding-right: 2rem;
  }
}