/*------------------------------------------------
:: BLOCK dossier
--------------------------------------------------*/

#strate-creation {
  // margin-bottom: s(8);
}

// Surcharge grid layout
#strate-creation .node-liste-container {
  grid-column: 1 / -1;
  display: block;
  display: grid;
  @include media-breakpoint-up($bp-grid-4fr){
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-up($bp-grid-6fr){
    grid-column: 1/ span 5;
  }
  .block-film-creation {
    grid-column: span 1;
  }
}

.block-film-creation {
  grid-column: 1 / -1;
  @include media-breakpoint-up($bp-grid-4fr){
    grid-column: span 2;
  }
  @include media-breakpoint-up($bp-grid-6fr){
    grid-column: span 3;
  }
  overflow: hidden;
  position: relative;
  @include min-ratio('3x2');
  background:var(--site-color);

  img {
    position:absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.block-film-creation a{
    display: block;
    color: #fff;
    &:focus img,
    &:hover img{
      filter: contrast(1.9) brightness(.6) contrast(.6);
    }
}
.block-film-creation figure img{
    width: 100%;
}
.block-film-creation a:hover figure img{
    opacity: 1 !important;
}

.block-film-creation-texte{
    position: absolute;
    padding:var(--gutter-x);
    bottom: 0;
    left: 0;
    z-index: 2;
}

.block-film-creation-titre {
  font-weight: bold;
  max-width: 30ch;
}

.block-film-creation-tag {
  top: -.2em;
  left:var(--gutter-x);
  position: absolute;
}

.block-film-creation-sous-titre {
  line-height: $line-height-sm;
  font-size: 1.2rem;
  max-width: 50ch;
}

.block-film-creation-theme {
  font-size: $font-size-sm;
  padding:  .2rem .7rem;
  border-radius: $border-radius;
  background-color: $white;
  color: $body-color;
  display: inline-block;
  margin-top: .25rem;
  text-transform: uppercase;
  font-weight: bold;
}