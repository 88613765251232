.menu-depliant {
  --menu-depliant-top : calc(1.5rem + var(--website-header-desktop-height, 20rem) + var(--toolbar-height, 0px)); 
  position: fixed !important;
  line-height: $line-height-sm;
  padding:4rem 0 ;
  min-height:70vh;
  overflow-y: auto;
  z-index: 31;
  top:var(--menu-depliant-top);
  height: calc(100vh - var(--menu-depliant-top,20rem));
  @include media-breakpoint-down(lg){
    display: none !important;
  }
}


.menu-depliant-image {
  position: absolute;
  right: 0;
  top:0;
  height: 100%;
  width:calc(var(--container-gutter-x) + 20vw);

  img {
    position: absolute;
    top:0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    filter: grayscale(100);
    opacity: .5;
    mix-blend-mode: luminosity;
  }

}

.menu-depliant-menu {
  border-left:1px solid;
  padding: 2rem;
}

.menu-depliant-texte-contenu {
  @include font-size($h4-font-size);
}

.menu-depliant-contenu {
  @include font-size($font-size-lg);

  // display: none;
  display:grid;
  grid-template-columns:1fr 1.8fr;
  grid-gap: 5rem;
  padding-right: 20vw;
  color: #fff;

  a {
    color: inherit;
  }

}


.menu-depliant-close {
  position: absolute;
  color:$white;
  right: 1rem;
  top:1rem;
  z-index: 10;
  opacity: 1;
  &:focus, 
  &:hover {
    color:$black;
    opacity: .8;
  }
}

.menu-depliant.pole-ciclic .menu-depliant-sous-menu{
  display: none !important;
}