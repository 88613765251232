@mixin grid-full(){
  grid-column-start: 1;
  grid-column-end: -1;
}

.grid-full  {
  @include grid-full;
}

@mixin c_grid(){
  display: grid;
  list-style: none;
  grid-template-columns: 1fr;
  
  > li {
    margin:0;
    padding: 0;
    list-style: none;
  }

  grid-template-columns: repeat(2, 1fr);
  
  @include media-breakpoint-up($bp-grid-2fr) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-up($bp-grid-3fr) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include media-breakpoint-up($bp-grid-4fr) {
      grid-template-columns: repeat(4, 1fr);
  }
  // @include media-breakpoint-up($bp-grid-5fr) {
  //     grid-template-columns: repeat(5, 1fr);
  // }
  @include media-breakpoint-up($bp-grid-6fr) {
      grid-template-columns: repeat(6, 1fr);
  }


  > div:not([class]):not([id]):not([style]),
  .node-liste-container > div:not([class]):not([id]):not([style]), // FIX div généré par ajax drupal
  .liste-bouton,
  .liste-affichage,
  .liste-filtres,
  .node-liste-header,
  .liste-separateur,
  .node-iframe,
  .bouton-afficher-plus-wrapper,
  .messages-liste-vide-container,
  .node-liste-container {
    @include grid-full;
  }
  
  @supports (display: contents) {
      > div:not([class]):not([id]):not([style]),
      .node-liste-container > div:not([class]):not([id]):not([style]),
      .node-liste-container,
      .node-liste {
        display: contents;
      }
  }

 

}
