.grande-carte {
  width: 100%;
  height: 40rem;
  max-height: 90vh;
}

.petite-carte {
  aspect-ratio: 16/9;
  min-height: 300px;
  width: 100%;
}

.btn-carte {
  display: inline-block;
  margin-top: .5em;
  border:1px solid;
  padding:.5em .8em;
  background:transparent;
  text-decoration: none;
  border-radius: $border-radius;
}

iframe {
  vertical-align: middle;
}


.google-maps-container {
  height: 40rem;
  max-height: 90vh;
  @include grid-full;

   * {
    box-sizing: content-box;
  }
}

