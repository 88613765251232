
.onglets-container {
  margin-top: s(5);
}

.onglet-contenu {
  display: grid;
  grid-gap: .8rem;
  margin-bottom: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr) );
  align-items: flex-end;
}


.nav-tabs + form .onglet-contenu {
  padding: 1.5rem  1rem ;
  margin-bottom: 0;
}

.onglet-recherche-intro {
  @include grid-full;

}


.affichage-liste-bouton .affichage-liste-icone {display: inline-block !important;}
.affichage-carte-bouton .affichage-carte-icone {display: inline-block !important;}
.affichage-mosaique-bouton .affichage-mosaique-icone {display: inline-block !important;}
.affichage-calendrier-bouton .affichage-calendrier-icone {display: inline-block !important;}
  

.catalogue-lien-processed {
  &:hover, 
  &:focus {
    cursor: pointer;
    .catalogue-table-title {
      text-decoration: underline
    }
  }
}

/*  ------------------------------------------------
    ::
    ::   CATALOGUE
    ::
    --------------------------------------------------*/

.onglet-contenu [data-multicritere]{
  @include grid-full;
}


.catalogue_genre{
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 1rem;

  a:first-child {
    width: 100%;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.1;
  }

  a.tag-niveau-2 {
    margin-top: .2rem;
    
  }
}


@media print {
  .annuaire-resultats table{
    font-size: 12px;
  }

  #tarteaucitronRoot{
    display: none;;
  }
}

/* -------------------------------------------------------------------------- */
/*                               NODE CATALOGUE                               */
/* -------------------------------------------------------------------------- */
.catalogue-fiche-container {
  @include shadowBlock(md);
}

// .liste-ouvrage {
//   display: block !important;
//   @include margin-bottom(7rem);
// }


.node-type-ouvrages .illustration-node{
  padding: 4rem 3rem;
  
  img {
    box-shadow: 0 2rem 3.5rem -2rem rgba(0,0,0,.2);
  }

  @include media-breakpoint-up(lg){
    padding: 5rem ;
    img {
      box-shadow: 0 2rem 5rem -2rem rgba(0,0,0,.25);
    }
  }

  background:$gray-100;
}