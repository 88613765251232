/* -------------------------------------------------------------------------- */
/*                                  BOOTSTAP                                  */
/* -------------------------------------------------------------------------- */

// Surcharge variables
// Bootstrap & SSKS
@import "./bootstrap/variables";

// BOOTSTRAP
// Configuration
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

/* -------------------------------------------------------------------------- */
/*                                    THEME                                   */
/* -------------------------------------------------------------------------- */
@import "./variables-app.scss";

/* -------------------------------------------------------------------------- */
/*                                  PACKAGES                                  */
/* -------------------------------------------------------------------------- */
@import "../@ssks/drupal/index";
@import "../@ssks/bootstrap/index";

/* -------------------------------------------------------------------------- */
/*                                     APP                                    */
/* -------------------------------------------------------------------------- */
@import "root";

// Theme
@import "mixins/layout";
@import "mixins/theme";

@import "theme/couleur";
@import "theme/layout";
@import "theme/buttons";
@import "theme/text-formatted";

@import "templates/page/page";

@import "templates/regions/offcanvas/offcanvas";
@import "templates/regions/header/header";
@import "templates/regions/header/main-menu";
@import "templates/regions/header/menu-haut";
@import "templates/regions/header/menu-langue";
@import "templates/regions/header/sous-menu";
@import "templates/regions/footer/footer";
@import "templates/regions/footer/menu-footer";

@import "templates/block/breadcrumb";
@import "templates/block/page-flip.scss";

@import "templates/menu/menu-outils";
@import "templates/menu/menu-depliant";
@import "templates/menu/menu-enfants";
@import "templates/form/form";
@import "templates/form/comment-form";

@import "templates/liste/node-liste";
@import "templates/liste/strate";
@import "templates/liste/liste-affichage";
@import "templates/liste/filtres";
@import "templates/liste/vue-planning";
@import "templates/liste/vue-calendrier";

@import "templates/liste/block-item";
@import "templates/liste/block-video";
@import "templates/liste/block-dossier";
@import "templates/liste/block-badge";
@import "templates/liste/block-film";
@import "templates/liste/memoire-block-film";
@import "templates/liste/memoire-home-block-creation";
@import "templates/liste/memoire-liste-films";
@import "templates/liste/memoire-liste-appareils";

@import "templates/liste/block-agenda-widget.scss";
@import "templates/liste/search-results.scss";

@import "templates/liste/catalogue";
@import "templates/liste/cinemobile-recherche-commune";
@import "templates/liste/slider-home";
@import "templates/liste/memoire-home-block-recherche.scss";
@import "templates/liste/memoire-home-block-chiffres.scss";
@import "templates/liste/memoire-home-link-block.scss";
@import "templates/liste/memoire-home-block-archives.scss";
@import "templates/liste/memoire-home-block-nouveaux.scss";
@import "templates/liste/memoire-home-block-actus.scss";

@import "templates/node/node";
@import "templates/node/node-structure";
@import "templates/node/node--memoire-film";
@import "templates/node/node--memoire-appareil";

@import "templates/node_block/node-galerie-photo-ged";
@import "templates/node_block/node-slider-video";
@import "templates/node_block/node-carte";
@import "templates/node_block/node-liens";
@import "templates/node_block/node-dates";
@import "templates/node_block/node-responsable";
@import "templates/node_block/node-commentaires";
@import "templates/node_block/node-accueil-commentaires";

@import "templates/components/throbber";

@import "templates/widgets/commentaires";
