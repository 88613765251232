.block-item {
    overflow: hidden;
    line-height: $headings-line-height;
}
.block-annee-fabrication{
  margin-top: .5rem;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
} 

.block-resume {
    margin-top: .5rem;
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    @include media-breakpoint-down(lg){
        display:none;
    }

    + .block-resume {
        display:none !important;
    }
}
.block-ressources .block-resume {
    display: none;
}

/* -------------------------------------------------------------------------- */
/*                                    image                                   */
/* -------------------------------------------------------------------------- */
.block-item {
    .block-image-wrapper {
        background:$gray-200;
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        @include media-breakpoint-up($bp-grid-2fr){
          border-radius: 0;
        }
    }

    img {
        width: 100%;
        height: 100%;
        transition: filter 0.1s;
        object-fit: cover;
        aspect-ratio: #{"1 / 1"};
    }

    // FIX Si image sans source
    // Conflit entre fontcionnement nouvelle liste et ancienne liste... Déso.
    img[data-src='']{
        display: none !important;
    }

    a:focus,
    a:hover {
        img {
            filter: grayscale(100) brightness(1.2) contrast(2) contrast(0.8);
        }
    }
}

/* -------------------------------------------------------------------------- */
/*                                    Hover                                   */
/* -------------------------------------------------------------------------- */
.block-item:not(.block-ressources){
    a:hover .block-texte,
    a:focus .block-texte{
        background-color: $gray-200;
    }
}

/* -------------------------------------------------------------------------- */
/*                                    Texte                                   */
/* -------------------------------------------------------------------------- */

.block-titre {
    font-weight: 600;
    margin:0;
}

.block-texte {
  @include media-breakpoint-up($bp-grid-2fr){
    @include min-ratio('4x3');
  }
  @include media-breakpoint-up($bp-grid-4fr){
    @include min-ratio('1x1');
  }
}

.block-texte-wrapper {
    word-wrap: break-word;
    word-break: break-word;
    padding: .5rem 1rem ;
  @include media-breakpoint-up(lg){
    padding: 1.2rem 1rem 1.5rem;
  }
}

.block-date,
.block-sous-titre {
    margin-top: 0.4rem;

  @include media-breakpoint-up(lg){
    margin-top: 0.8rem;
}

    margin-bottom: 0;
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    &::first-letter{
        text-transform: uppercase;
    }
}

.block-tag {
    margin-bottom: 0.4rem;
    text-transform: uppercase;
}

.block-lien {
    text-decoration: none;
    display: block;
}

/* -------------------------------------------------------------------------- */
/*                           Bloc-lien mobile                                 */
/* -------------------------------------------------------------------------- */

@mixin block-item-xs ($text-min-ratio : '11x4'){
    .block-item:not(.block-badge) {
        grid-column: span 2;
    }
    
    .block-item{
        &:not(:last-child){
            margin-bottom: 1rem;
        }

        .block-lien {
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
            align-items: center;
        }
        .block-titre{
            font-size: 1rem;
        }
        .block-item img {
            min-height: 100%;
            height: 100%;
        }

        .icone-ressource {
            font-size: 1.25rem;
        }
        
      }

    .block-item:not(.block-ressources){
      .block-image-wrapper {
        border-radius: 10px;
        overflow: hidden;
      }
    }
      
    .block-item:not(.block-ressources){
        a:hover .block-texte,
        a:focus .block-texte {
          background-color: transparent !important;
          color: var(--site-color);
        }
    }

    .block-item.block-ressources .block-lien{
        align-items: stretch;
        .block-image-wrapper {
            border-radius: 10px 0 0 10px;
            overflow: hidden;
          }

        .block-texte {
            align-items: center;
            border-radius: 0 10px 10px 0 ;
            svg  {
                display: none;
            }
        }
    }



    @if $text-min-ratio  {
        .block-texte {
            @include min-ratio($text-min-ratio);
        }
    }
}


@include media-breakpoint-down($bp-grid-2fr){
   @include block-item-xs;
}

.block-items-carte {
  max-width: 25rem;
  font-family: $font-family-sans-serif;
    @include block-item-xs;

    .block-texte .block-titre {
      font-size: 1rem;
    }

    .block-item + .block-item{
        // border-top:2px solid; 
    }

    @include media-breakpoint-down(md){
        .block-image-wrapper {
            display: none;
        }
        .block-lien {
            grid-template-columns: 1fr !important;
        }
    }

}




/* -------------------------------------------------------------------------- */
/*                                 GRAND BLOC                                 */
/* -------------------------------------------------------------------------- */
@include media-breakpoint-up($bp-grid-2fr){

    .block-item[class*="grand-bloc"] {
        position: relative;
        grid-column: span 2;

        .block-texte {
            overflow: hidden;
            width: 50%;
            position: absolute;
            transform: translateZ(0);
        }

        &:not(.block-ressources){

            .block-texte {
                background: $white;
                
                a:hover &,
                a:focus  &{
                    background-color: $gray-200;
                }
            }
        }
    }
}

.block-texte {
    .grand-block-item-1 & {
        top: 0;
        left: 0;
    }
    .grand-block-item-2 & {
        top: 0;
        right: 0;
    }
    .grand-block-item-3 & {
        top: auto;
        bottom: 0;
        right: 0;
    }
    .grand-block-item-4 & {
        top: auto;
        bottom: 0;
        left: 0;
    }
}

/* -------------------------------------------------------------------------- */
/*                                 RESSOURCES                                 */
/* -------------------------------------------------------------------------- */
.block-ressources {
    color: #fff;

    &:nth-child(2n + 1) .block-texte {
        background-color: $gray-900;
    }

    a {
        color: #fff;
        transition: 0.1s;
    }

    .couleur-texte.pole-ciclic{
        color: $gray-500;
    }
    
    .block-texte {
        background: $black;
        a:hover &,
        a:focus  &{
            background-color: $gray-800;
        }
    }
}


/* -------------------------------------------------------------------------- */
/*                                  has-video                                 */
/* -------------------------------------------------------------------------- */
.has-video .block-image-wrapper{
    position: relative;
    &::after{
        display: block;
        position:absolute;
        bottom: 1rem;
        left: 1rem;
        content: '';
        border-left: 1.2rem solid #fff;
        border-top: .7rem solid transparent;
        border-bottom: .7rem solid transparent;
        z-index: 10;
    }
}

@include media-breakpoint-up($bp-grid-3fr){
    .grand-block-item-4 .block-image-wrapper:after{
        right:1rem;
        left: auto;
    }
}
