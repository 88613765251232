@mixin shadow(){
  box-shadow: 0 9px 20px -5px rgba(0, 0, 0, .2);
}

@mixin shadowBlock($bp: md, $mb : 6rem) {
  @include margin-bottom($mb);
  @include media-breakpoint-up($bp){
    padding: 1.5rem 1rem 1rem;
    box-shadow: 0 9px 20px -5px rgb(0 0 0 / 20%);
    border-radius: .8rem;
  }
}

@mixin memoire-slider-date {
  .memoire-slider-date {
    margin-top: 1rem;
    @include media-breakpoint-up(lg){
      margin-left: 1.5rem;
      margin-top: 0;
    }
    min-width: min(30rem, 100%);
    max-width: 100%;
  }
  .irs {
    font-family: "PTRoot", Arial, Helvetica, sans-serif !important;
    font-weight: 600;
  }
  .irs-line {
    background-color: $gray-200 !important;
  }

  .irs-bar {
    background-color: $gray-900 !important;
  }

  .irs-handle {
    background-color: $gray-900 !important;
    border: 0 !important;
  }

  .irs-to, .irs-from {
    background-color: transparent !important;
    color: $gray-900 !important;
    &:before {
      display: none !important;
    }
  }

  .irs-min, .irs-max {
    background: none !important;
    color: $gray-400 !important;
  }
}