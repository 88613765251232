

/*
Couleur animation
*/

/*Lien langue*/
.lien-langue-container {
  display: flex;
  justify-content: flex-end;
  top: 25px;
  right: 12px;
  z-index: 9;
}

.lien-langue {
  display: flex;
  width: 1.5rem;
  margin-left: .7rem;
  box-shadow: 0 0 0 3px #fff, 0 0 0 3px #fff;
      border-radius: 50%;
      transition:.2s;
} 

.lien-langue:focus,
.lien-langue:hover,
.lien-langue.active {
  box-shadow: 0 0 0 3px #fff, 0 0 0 7px #ddd;
}

.lien-langue img {
  width: 100%;
  vertical-align: middle;
  margin:0;
}

@media screen and (min-width: 992px) {
  .lien-langue-container {
      right: 100px;
  }
}
