.video-slider-description-container {
  position: relative;
  background-color: $black;
  color:#fff;

  @include media-breakpoint-down(md){
    height: 15rem;
  }
  .video-slider-description-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}

.node-galerie-video-slider-item {
  border:none;
  box-shadow: none;
  background:$black;
  position: relative;
  @include min-ratio('1x1');

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &.active img {
    opacity: .1;
    filter:grayscale(100) contrast(2) contrast(.7);
  }

  
}

.slider-video-nav .swiper-wrapper {
  background: $gray-900;
}


 .node-galerie-video-slider-item-icone {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 10;
  opacity: .25;
  color:#fff;
  font-size: 3rem;
  mix-blend-mode: screen;

  .active &{
    display: block;
  }
}


.slider-video-nav-titre {
  position: absolute;
  color:#fff;
  font-size: .85rem;
  overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  z-index: 2;
  bottom:.8rem;
  left:.8rem;
  right:.8rem;
  line-height: 1;
  text-transform: none;
 
}