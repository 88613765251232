/* -------------------------------------------------------------------------- */
/*                                   STRATE                                   */
/* -------------------------------------------------------------------------- */

.strate {
  position: relative;
    @include c_grid();
}

.strate-titre-icone {
    width: 4.2rem;
    margin-right: 0.4rem;
    img {
        width: 100%;
        height: auto;
    }
}

.strate-titre {
    display: flex;
    align-items: center;
    text-decoration: none;
    h2 {
        margin: 0;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    margin-top: #{calc(var(--gutter-x) * 2)};

    grid-column: #{"-1 / 1"};
    margin-bottom: 1.2rem;
    text-transform: lowercase;
}

a.strate-titre:hover{
  color: var(--site-color);
}
