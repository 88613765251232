/* -------------------------------------------------------------------------- */
/*                            Ratio d'image du site                           */
/* -------------------------------------------------------------------------- */
// LazySizes :
// ANIMATION de l'apparition (fondu)
$lazy-animation-opacity: true ;

$lazy-placeholder-bg: $gray-200 !default;
$lazy-opacity-loaded: 1 !default;
$lazy-animation-duration: 0.2s !default;


/* -------------------------------------------------------------------------- */
/*                               Texte formatté                               */
/* -------------------------------------------------------------------------- */
$text-max-width: 90ch !default;
$form-max-width: 90ch !default;

/* -------------------------------------------------------------------------- */
/*                                    INDEX                                   */
/* -------------------------------------------------------------------------- */
$z-index-outils: 80;


$video-block-bg :#151515;



/* -------------------------------------------------------------------------- */
/*                               GRID BREAKPOINT                              */
/* -------------------------------------------------------------------------- */
$bp-grid-2fr : sm ;
$bp-grid-3fr : md ;
$bp-grid-4fr : lg ;
// $bp-grid-5fr : xl ;
// $bp-grid-6fr : 3xl ;
$bp-grid-6fr : xl ;
