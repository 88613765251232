

  .menu-mobile-container {
    display: flex;
    flex-direction: column;
    height: 0;
    line-height: 1.2;
    padding:1.8rem 1.5rem;
    background: #fff;

}


.menu-mobile-deplier {
  display: flex;
  width: 100%;
  align-items: center;

  .deplier-bouton {
    margin-left: auto;
    padding:.25rem .5rem;
    color: $gray-300;
    text-decoration: none;
  }

  .fa-chevron-up {
    display: none;
  }

  .deplier-bouton.active {

    .fa-chevron-up {
      display: block;
    }
    .fa-chevron-down {
      display: none;
    }
  }

}

/* -------------------------------------------------------------------------- */
/*                                    MENUS                                   */
/* -------------------------------------------------------------------------- */

.menu-mobile {
  ul {
    list-style: none;
    margin:0;
    padding: 0;

    li {
      margin-left:0 ;
      margin-right:0 ;
      padding: 0;
    }
  }

  &:not(:first-child){
    margin-top: 2rem;
  }
}


.menu-mobile  a {
  text-decoration: none;
  &.active {
    text-decoration: underline;
  }
}
.menu-mobile-1.active .menu-mobile-niveau-1 .menu-texte {
  text-decoration: underline;
}
.menu-mobile-1 {
  margin-bottom: .5rem;

}

.menu-mobile-niveau-1 {
  font-weight: bold;
  line-height: $line-height-sm;
}

.menu-mobile:first-child .menu-mobile-niveau-1 {
  text-transform: uppercase;
  font-size: 1.6rem;
}


// NIVEAU 2 collapse
.menu-mobile > ul  ul {
  display: none;
  margin-left: .8rem;
  padding: .5rem 0 1.5rem;

  li {
    margin-bottom:.33rem ;
  }
}

.menu-mobile-1.active > ul {
  display: block;
}


// CACHE niveau 3
.menu-mobile > ul  ul ul{
  display: none !important;
}

/* -------------------------------------------------------------------------- */
/*                                    FOOTER                                  */
/* -------------------------------------------------------------------------- */
.menu-mobile-footer-menu-footer {
  display:none !important;
}



/*  ------------------------------------------------
::  LAYOUT MENU MOBILE + OVERLAY
--------------------------------------------------*/
/* General styles for all menus */
.cbp-spmenu {
  position: fixed;
  width: 85%;
  height: 100%;
  max-width: 30rem;
  top: 0;
  z-index: 1000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}



/* Vertical menu that slides from the left or right */
.cbp-spmenu-left {
  transform: translate(-101%,0) translateZ(0) ;
  left: 0;
}

.cbp-spmenu-right {
  transform: translate(101%,0) translateZ(0) ;
  right: 0;
}

.cbp-spmenu-left.cbp-spmenu-open {
  transform: translate(0,0) translateZ(0) ;
}

.cbp-spmenu-right.cbp-spmenu-open {
  transform: translate(0,0) translateZ(0) ;
}


/* Push classes applied to the body */
.cbp-spmenu-push {
  overflow-x: hidden;
  position: relative;
  left: 0;
}

/* OVERLAY */
.overlay-mobile {
  pointer-events: none;
  position: fixed;
  background: rgba(220,220,220,.8);
  left: 0;
  right: 0;
  top: 0;
  height: 0;
  z-index: 990;
  transform: translateZ(0);
  transition: opacity .3s ease, height 0s ease 0.3s;
  will-change: opacity;
  opacity: 0;
}


.cbp-spmenu-push-toright,
.cbp-spmenu-push-toleft {

  .overlay-mobile ,
  .overlay-mobile {
      opacity:1;
      height: 100%;
      transition: opacity .3s ease;
      pointer-events: auto;
  }

}

.no-csspointerevents {
  .overlay-mobile {
      display:none;
  }

  .cbp-spmenu-push-toright,
  .cbp-spmenu-push-toleft {

      .overlay-mobile {
          display:block;
      }
  }
}


/* Transitions */
.cbp-spmenu {
  transition: transform .2s ease-out;
  will-change: transform;
}



// ESPACE PRO EN MOBILE
.menu-mobile-footer-menu-memoire-espace-pro {
  text-transform: uppercase;
  a {
    background: $pro;
    padding: .5rem 1rem;
    border-radius: 10rem;
  }
  .deplier-bouton,
  ul ul {
  display: none !important;
  } 
}