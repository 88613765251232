.block-badge {
  position: relative;
  overflow: hidden;
  align-self: start; //context-grille
  @include min-ratio('1x1');
  
  a {
    display: block;
  }
  
  &:focus,
  &:hover {
    filter: grayscale(1) brightness(.8) contrast(1.8) ;
  }

  img {
    position:absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}