/* -------------------------------------------------------------------------- */
/*                                   LAYOUT                                   */
/* -------------------------------------------------------------------------- */

.node-appareil-grid {
  display: grid;
  grid-gap: 0 calc(var(--gutter-x) *2);
  @include media-breakpoint-up(lg) {
    grid-template-columns:  minmax(0, 1fr) 1fr ;
  }
  @include media-breakpoint-up(xl){
    grid-template-columns: minmax(0, 2fr) 1fr ;
  }
}

.node-appareil-cell-left, .node-appareil-cell-right {
  margin-bottom: var(--gutter-y);
  @include media-breakpoint-up(lg){
    margin-bottom: 0;
  }
}

.node-appareil-cell-right {
  padding-top: var(--gutter-y);
  grid-row: 1;
  @include media-breakpoint-up(lg){
    grid-row: inherit;
  }

}

/* -------------------------------------------------------------------------- */
/*                                   CONTENT                                  */
/* -------------------------------------------------------------------------- */

.node-appareil-content {
  .node-appareil-content-main {
    margin-bottom: 1.5rem;
  }
}

.node-appareil-informations {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  list-style: none;
  padding-left: 0;
  border-top: 1px solid $primary;
  padding-top: 1rem;
  margin-bottom: 2rem;

  .grid-full {
    grid-column: 1 / -1;
  }

  .node-appareil-informations-dimensions {
    span:first-child {
      margin-right: .25rem;
    }
    display: inline-flex;
    .dimensions-item:not(:last-of-type) {
      &:after {
        content: "x";
      }
    }
  }
}


/* -------------------------------------------------------------------------- */
/*                                ILLUSTRATION                                */
/* -------------------------------------------------------------------------- */

.node-appareil-illustration {
  overflow: hidden;
  position: relative;
  margin-bottom: calc(var(--gutter-y) * 2);
  .node-illustration {
    display: block !important;
    margin-bottom: 0;
  }
  img {
    aspect-ratio: #{"16 / 9"};
    object-fit: cover;
    object-position: center;
    width: 100%;
    @include media-breakpoint-up(lg){
      aspect-ratio: #{"4 / 3"};
    }
  }
}

/* -------------------------------------------------------------------------- */
/*                                VIDEO GALLERY                               */
/* -------------------------------------------------------------------------- */

.node-appareil-cell-left {
  .video-block-container {
    margin-bottom: 1rem;
  }
}

/* -------------------------------------------------------------------------- */
/*                             LISTE PIED DE PAGE                             */
/* -------------------------------------------------------------------------- */

.liste-ref .liste-ref-titre {
  margin-top: calc(var(--gutter-x) *2);
  display: flex;
}