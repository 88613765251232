/* -------------------------------------------------------------------------- */
/*                                CHIFFRES HOME                               */
/* -------------------------------------------------------------------------- */

// Layout

.home-chiffres-text {
  align-self: center;
  grid-column: 1/-1;
  max-width: 50ch;
  @include media-breakpoint-up($bp-grid-4fr){
    grid-column: span 2;
  }
  @include media-breakpoint-up($bp-grid-6fr){
    grid-column: span 2;
  }
}

.home-chiffres-illustration {
  min-height: 20rem;
  grid-column: 1/-1;
  @include hors-container("r");
  max-height: 20rem;
  @include media-breakpoint-up($bp-grid-6fr){
    margin-bottom: calc(var(--strate-ecosysteme-height) * -1);
    grid-column: 5 / span 2;
    max-height: unset;
  }
}

.home-chiffres-data {
  grid-column: 1/-1;
  @include media-breakpoint-up($bp-grid-4fr){
    grid-column: 3/span 2;
  }
  @include media-breakpoint-up($bp-grid-6fr){
    grid-column: 3/ span 2;
  }
}

// Styling

.home-chiffres-illustration {
  background-color: $green;
  position: relative;
  overflow: hidden;

  iframe,
  video,
  img {
    overflow: hidden;
    object-fit: cover;
    height: 102%;
    width: 102%;
    margin:-1%;
    object-position: 50% 50%;
  }
}

.home-chiffres-text, .home-chiffres-data {
  margin: calc(var(--gutter-y) * 2) 0 0 0;
  @include media-breakpoint-up($bp-grid-4fr){
    margin: calc(var(--gutter-y) * 2) 0;
  }
}

.home-chiffres-data .swiper-slide {
  padding-bottom: s(2);
  @include media-breakpoint-up($bp-grid-4fr){
    padding-bottom: s(4);
  }
}
.home-chiffres-data .swiper-pagination {
  padding: 0;
  .swiper-pagination-bullet {
    background-color: transparent;
    border: 2px solid $primary;
    width: 1rem;
    height: 1rem;
  }
}

.home-chiffres-data-container{
  width: fit-content;
  margin: 0 auto calc(var(--gutter-y) * 2) auto;
  // @include media-breakpoint-up($bp-grid-4fr){
  //   margin: calc(var(--gutter-y) * 2) auto;
  // }
}

// Chiffres Item
.home-chiffres-data-item {
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  @include media-breakpoint-up($bp-grid-6fr){
    margin: s(4);
    display: flex;
    flex-direction: row;
    .home-chiffres-data-item-icon {
    }
  }
}

.home-chiffres-data-item-icon {
  max-width: 5rem;
  width: 100%;
  // height: auto;
  overflow: visible;
  padding: 0 s(3);
  flex-shrink: 0;
  @include media-breakpoint-up($bp-grid-6fr){
    max-width: 50%;
  }
}
.home-chiffres-data-item-text {
  border-left: 1px solid $primary;
  padding: s(3);
}
