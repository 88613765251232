.ajax-progress{

  &.ajax-progress-throbber,
  &.ajax-progress.ajax-progress-fullscreen{
    border-radius: 50%;
    z-index: 10;
    border-radius: 50px;
    background:  var(--throbber-bg, #666);
    mix-blend-mode: difference;
    animation: scaleout 0.8s infinite ease-in-out;
    font-size: 0;
  }

  // Fullscreen
  // &.ajax-progress-fullscreen{
  &.ajax-progress-throbber{
    position:fixed;
    z-index: 10;
    width: 6rem;
    height: 6rem;
    margin: -2rem 0 0 -2rem;
    border-radius: 6rem;
    left: 50%;
    top: 50%;
  }

  // Loader en ligne, plus petit
  // &.ajax-progress-throbber {
  //   position: absolute;
  //   margin-left: 1rem;
  //   width: 2rem;
  //   height: 2rem;
  // }

  // Cache image par défaut
  .throbber {
    display:none;
  }
}



@keyframes scaleout {
  0% {
      opacity: 1;
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
  }
  100% {
      transform: scale(1.0);
      opacity: 0;
  }
}

