.liste-block-container > form ,
.formulaire-contact {
  max-width: $form-max-width;
  margin-bottom: s(8);
  @include grid-full;
}


// CAPTCHA
.plus-sign-fix:before {
  content: "\002B";
}
.moins-sign-fix:before {
  content: "\002D";
}


.form-item{
  margin-bottom: var(--gutter-y, 1.2rem);
}

form .description{
  color: $text-muted;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
}

#user-login {
  max-width:40ch;
}


/* -------------------------------------------------------------------------- */
/*                                 password                                   */
/* -------------------------------------------------------------------------- */
form[id*=user-pass]{
  @include padding(1.5rem);
  border: 1px solid $light; 

  max-width: 35rem;
  padding: 1.2rem;
}

form[id*=user-profile-form] {
  margin-bottom: 2.5rem;
  .password-strength, div.password-confirm{
    display: none;
  }

  fieldset {
    @include padding(1.5rem);
    border: 1px solid $light; 
    margin: 1.5rem 0;
    max-width: 35rem;
  }

  // padding: 1.2rem;
}