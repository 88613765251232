
#calendar {
  @include grid-full;
  @include margin-bottom(7rem)
}


@include media-breakpoint-down(md){
  
  #calendar thead {
    display: none;
  }
  
  #calendar tbody ,
  #calendar td ,
  #calendar table ,
  #calendar tr {
      display: block;
      width: 100%;
      float: left;
  }
  
  #calendar .ui-widget-content {
      border-width:1px;
      display: block;
      float: left;
  }
  
  
}


/*  --------------------------------------------------
    :: Full calendar
    --------------------------------------------------*/
    .fc tbody {border:none;}
    .fc-header tr td{
      padding: 1.5em 0 0.5em;
    }
    #calendar .ui-widget-content{ border-color:$gray-200;}
    .fc-first .ui-widget-header {
         text-transform:uppercase;
         background:transparent;
         border-color:transparent;
         border-bottom:1px solid $gray-200;
    }
    .fc .fc-today {
         background:$gray-200;
    }
    .fc .fc-event-skin {
         background-color:$agenda;
    }

    .fc-event {
     border-bottom: 2px solid #fff;
      border-radius: 4px;
      overflow: hidden;
      line-height: $line-height-sm;
      font-size: .75rem;

      &:focus,
      &:hover {
        background:$black !important;
        .fc-event-inner {
          background-color: inherit !important;
        }
      }
    }