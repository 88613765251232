
.responsable-block-container {
    margin-bottom: 3rem;
}
.responsable-block-content {
    display: grid;
    grid-gap: 1.5rem;
    overflow-wrap: break-word;
    word-break: break-word;
    background-color: #fff;
    @include media-breakpoint-up(sm){
        grid-template-columns: 1fr 1fr;
    }
    @include media-breakpoint-up(lg){
        grid-template-columns: 1fr ;
    }
    @include media-breakpoint-up(3xl){
        grid-template-columns: 1fr 1fr;
    }
}
    
.block-texte-actions {
    margin-top: .5rem;
    a {
        color: $text-muted;
    }
}

.responsable-block {
    padding: 1rem;
    box-shadow:0 10px 15px -3px rgba(0,0,0,0.1),0 4px 6px -2px rgba(0,0,0,0.05);
    border-radius: .5rem;
    position: relative;
}

.responsable-block {
    display: flex;
    align-items: center;
}

.block-texte-img-container {
    border-radius: $border-radius;
    overflow: hidden;
    width: 6rem;
    margin-right: 1rem;
    flex-shrink: 0;

    img {
        object-fit: cover;
    }
}

.responsable-actions {
    margin-right: .5rem;
    margin-top: .8rem;
}


.responsable-lien-tous {
    position: absolute;
    right:.8rem;
    bottom:.7rem;
    color:$gray-400;
    line-height: 1;

    

}


body.pole-ciclic {
    .responsable-block-container .titre-block {
        display: none;
    }
    
    .responsable-block .responsable-lien-tous{display:none;}
}