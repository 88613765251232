  /*  --------------------------------------------------
    :: page-flip
    --------------------------------------------------*/
    #page-flip {display:none;}
    .page-flip-logo {
        position:absolute;
        z-index: 50;
        top:10px;
        right:3px;
    }
    .page-flip-coin  {
        position:absolute;top:0;left:0;width:100%;height: 100%;
    }
    @include media-breakpoint-up(md){
        
        .mini-site #page-flip {
            -webkit-transition:all 0.5s ease;
            -moz-transition   :all 0.5s ease;
            -o-transition     :all 0.5s ease;
            -ms-transition    :all 0.5s ease;
            transition        :all 0.5s ease;
            overflow:hidden;
            display:block;
            z-index:50;
            position:absolute;
            background:#444 url(../../img/page-flip-fleche.png) no-repeat -250px 110px;
            width: var(--container-gutter-x);
            height:var(--container-gutter-x) ;
            right:0;
            top:0;
            box-shadow:0 0 6px #666;
    
            @include media-breakpoint-up(xl){
                width: 60px;
                height:60px ;
                
            }
        }
    
         .mini-site #page-flip:hover{
            width: 380px ;
            height: 280px ;
            background-position:160px 110px;
            box-shadow:0 0 10px #777;
         }
    }