html {
  font-size: var(--fs-html);
}

.row:not([class*=gx-]):not([class*=gy-]) {
  --#{$variable-prefix}gutter-x: var(--gutter-x);
  --#{$variable-prefix}gutter-y: var(--gutter-y);
}

.hidden  {
  display: none !important;
}

.hidden-empty:empty{
  display: none !important;
}