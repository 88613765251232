/* -------------------------------------------------------------------------- */
/*                                 Block video                                */
/* -------------------------------------------------------------------------- */


@include media-breakpoint-up($bp-grid-2fr){
    .block-video {
        grid-column: span 2;
      
    }
}

@include media-breakpoint-up($bp-grid-4fr){

    .block-video {
        grid-column: span 4;
        display: grid;
        grid-template-columns: 3fr 1fr;
      
    }
}





.block-video {
    .block-video-container {
        position: relative;
        background-color: $video-block-bg;
        height: auto;
        @include min-ratio('16x9');
            iframe {
                width: 100%;
                height: 100%;
                position: absolute;
        }
    }
    a.block-lien{
        .block-image-wrapper {
            display: none;
        }
        background:$gray-100;
        text-decoration: none;

        &:focus,
        &:hover{
            background-color: $gray-200;
        }
    }

}
