.btn-square,
.btn-circle {
  --btn-height: calc(#{$btn-border-width}*2 + #{$btn-padding-y}*2 + #{$btn-line-height} * 1em);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: var(--btn-height);
  height: var(--btn-height);
  padding: 0;
}

.btn-circle {
    border-radius: 50% !important;
}


.btn-light {
  &.active {
    background-color: var(--site-color);
    color: #fff;
    border-color: transparent;
  }
}

.btn.rounded-pill {
  padding-inline: 1.3em;
}