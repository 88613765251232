/* -------------------------------------------------------------------------- */
/*                                   Fonts                                    */
/* -------------------------------------------------------------------------- */
$font-family-sans-serif: 'PTRoot', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: $font-family-sans-serif ;

/* -------------------------------------------------------------------------- */
/*                                  Couleurs                                  */
/* -------------------------------------------------------------------------- */
$white:    #fff    ;
$gray-100: #f8f9fa ;
$gray-200: #e9ecef ;
$gray-300: #dee2e6 ;
$gray-400: #ced4da ;
$gray-500: #999999 ;
$gray-600: #6c757d ;
$gray-700: #495057 ;
$gray-800: #343a40 ;
$gray-900: #121212 ;
$black:    #000    ;

$blue:    #3f69b2 ;
$red:     #fc3f59 ;
$orange:  #ff9761 ;
$yellow:  #ffee00 ;
$green:   #53b166 ;

$primary:       $gray-900 ;
$secondary:     $gray-600 ;
$success:       $green ;
$info:          $blue ;
$warning:       $orange ;
$danger:        $red ;
$dark:          $black ;
$light:         $gray-200 ;

$livre : #c64766;
$cinema : #9e6996 ;
$patrimoine : $green;
$education : #59a5a0;
$animation : #ff7352 ;
$upopi : #16aee5;
$agenda : #506fa4;
$actualites : $agenda;
$pro:  $yellow ;


$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "white":      $white,
  "upopi":      $upopi,
  "cinema":     $cinema,
  "patrimoine": $patrimoine,
  "animation":  $animation,
  "education":  $education,
  "agenda":     $agenda,
  "livre":      $livre,
  "pro":      $pro,
);


/*------------------------------------------------
:: BREAKPOINTS
--------------------------------------------------*/
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  3xl: 1700px,
  4xl: 2000px,
);


/*------------------------------------------------
:: FONT SIZE
--------------------------------------------------*/
$fs-html: (
  xs: 16px,
  sm: 17px,
  md: 16px,
  lg: 14px,
  xl: 15px,
  xxl: 16px,
  3xl: 17px,
  4xl: calc(17px + .1vw),
);
    

$font-size-base: 1rem ; // Assumes the browser default, typically `16px`

$h1-font-size: $font-size-base * 2.25 ;
$h2-font-size: $font-size-base * 1.85 ;
$h3-font-size: $font-size-base * 1.60 ;
$h4-font-size: $font-size-base * 1.35 ;
$h5-font-size: $font-size-base * 1.20 ;
$h6-font-size: $font-size-base ;

$headings-line-height:        1.15 ;

$display-font-sizes: (
  1: 3rem ,
  2: 2rem,
  3: 1.75rem,
  4: 1.35rem ,
  5: 1.20rem ,
  6: .85rem ,
); 

$display-font-weight: 300;
$display-line-height: $headings-line-height;


// $fs-breakpoints: (
//   xs: 18,
//   lg: 18,
// );


// $fs-list: (
//   xs: (
//     //  Headings
//     h1: 32,
//     h2: 24,
//     h3: 21,
//     h4: 19,
//     h5: 16,
//     h6: 14,
//     //  Displays
//     d1: 28,
//     d2: 22,
//     d3: 18,
//     //  Small
//     sm1: 12,
//     sm2: 10,
//   ),
//   lg: (
//     //  Headings
//     h1: 52,
//     h2: 32,
//     h3: 26,
//     h4: 24,
//     h5: 18,
//     h6: 16,
//     //  Displays
//     d1: 42,
//     d2: 28,
//     d3: 21,
//     //  Small
//     sm1: 13,
//     sm2: 11,
//   )
// );

// Surcharge des variables bootstrap
// $h1-font-size:   var(--fs-h1);
// $h2-font-size:   var(--fs-h2);
// $h3-font-size:   var(--fs-h3);
// $h4-font-size:   var(--fs-h4);
// $h5-font-size:   var(--fs-h5);
// $h6-font-size:   var(--fs-h6);

// $display1-size:  var(--fs-d1);
// $display2-size:  var(--fs-d2);
// $display3-size:  var(--fs-d3);
// $display4-size:  var(--fs-d3);

// $sm1-font-size:  var(--fs-sm1);
// $sm2-font-size:  var(--fs-sm2);

/* -------------------------------------------------------------------------- */
/*                                   GLOBAL                                   */
/* -------------------------------------------------------------------------- */

$enable-shadows: true ;


/* -------------------------------------------------------------------------- */
/*                                   Marges                                   */
/* -------------------------------------------------------------------------- */

$enable-negative-margins: true;

$spacer: 1rem ;
$spacers: () ;
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 3),
  7: ($spacer * 4.5),
  8: ($spacer * 6),
  9: ($spacer * 8),
  10: ($spacer * 10)
), $spacers);

/* -------------------------------------------------------------------------- */
/*                                   SHADOWS                                  */
/* -------------------------------------------------------------------------- */

$box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
$box-shadow-sm: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$box-shadow-lg: 0 15px 50px -10px rgba(0, 0, 0, 0.25) ;
$box-shadow-inset: inset 0 1px 2px rgba($black, .075) ;

/* -------------------------------------------------------------------------- */
/*                         Variables de configuration                         */
/* -------------------------------------------------------------------------- */
$enable-rounded: true;
// $border-width : .15rem;

/* -------------------------------------------------------------------------- */
/*                         Surcharge Bootstrap / ssks                         */
/* -------------------------------------------------------------------------- */
$body-color: $gray-900;
$link-color: $body-color;


/* -------------------------------------------------------------------------- */
/*                                 breadcrumbs                                */
/* -------------------------------------------------------------------------- */
$breadcrumb-divider-color:          inherit ;
$breadcrumb-active-color:           inherit ;



/* -------------------------------------------------------------------------- */
/*                                    input                                   */
/* -------------------------------------------------------------------------- */
$input-bg:                              $light;
$input-disabled-bg:                     $gray-200;
// $input-disabled-border-color:           null;
$input-border-color:                    transparent;
$input-focus-border-color:              transparent;
$btn-disabled-opacity:        .35;


/* -------------------------------------------------------------------------- */
/*                                     hr                                     */
/* -------------------------------------------------------------------------- */
$hr-margin-y:                 2.5rem;
$hr-opacity:                  .10 ;

/* -------------------------------------------------------------------------- */
/*                                     TAB                                    */
/* -------------------------------------------------------------------------- */
$nav-tabs-border-color:             $black ;
// $nav-tabs-border-width:             .15rem ;
$nav-tabs-link-hover-border-color:  $black $black $nav-tabs-border-color ;
$nav-tabs-link-active-color:        $gray-700 ;
$nav-tabs-link-active-bg:           $white ;
$nav-tabs-link-active-border-color: $black $black $nav-tabs-link-active-bg ;



/* -------------------------------------------------------------------------- */
/*                                   BUTTONS                                  */
/* -------------------------------------------------------------------------- */
$btn-border-radius: 50rem;
$border-radius-lg: .5rem;
$btn-hover-bg-tint-amount: 25%;

$btn-padding-x: 1.3em;
$btn-font-weight: bold;

/* -------------------------------------------------------------------------- */
/*                                    TABS                                    */
/* -------------------------------------------------------------------------- */
$nav-tabs-border-color: $light;

$nav-link-color: $gray-500;

$nav-tabs-link-hover-border-color: $body-color;

$nav-tabs-link-active-color : var(--site-color);
$nav-tabs-link-active-bg : transparent;
$nav-tabs-link-active-border-color: var(--site-color);

/* -------------------------------------------------------------------------- */
/*                                  DROPDOWNS                                 */
/* -------------------------------------------------------------------------- */

$dropdown-box-shadow: $box-shadow-lg ;
$dropdown-border-width: 0 ;
$dropdown-padding-y:                .5rem ;
$dropdown-item-padding-y:           .5rem ;
$dropdown-item-padding-x:           1.5rem ;
