.breadcrumb-item {
  font-size: .8rem;
  color:$gray-500;
  
  a {
    color: inherit;
    text-decoration: none;
    @include l-hover();
  }

  .active {
    @include l-hover-start();
    @include l-hover-end();
  }
}

// FORM
.front,
.forme-home, 
.node-type-home-strates {
  .breadcrumb {display:none;}
}