.node-liste-pole {
  text-transform: uppercase;
  display: inline-flex;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  line-height: $line-height-sm;
  a {
    text-decoration: none;
    margin-right: 1rem;
    @include l-hover();
  }
}

.node-date-publication {
  color: $text-muted;
  display: inline-flex;
  font-size: $font-size-sm;
  line-height: $line-height-sm;
  color: $gray-500;
}

/* -------------------------------------------------------------------------- */
/*                                   HEADER                                   */
/* -------------------------------------------------------------------------- */
.node-header {
  margin-bottom: 1.5rem;
}

.contexte-node-name-films-darchives .node-header,
.contexte-node-name-films-darchives nav[aria-label="breadcrumb"] {
  display: none;
}

.node-titre {
  font-weight: bold;
  max-width: 40ch;
  margin-bottom: 0.2rem;
}

.node-sous-titre {
  font-weight: 400;
  line-height: $headings-line-height;
}

.liste-block-container {
  @include c_grid();
}

.aside-liste-block-container {
  @include media-breakpoint-up($bp-grid-4fr) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up($bp-grid-6fr) {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* -------------------------------------------------------------------------- */
/*                               NODE - content                               */
/* -------------------------------------------------------------------------- */
.node-content-padding {
  @include media-breakpoint-up(lg) {
    padding-right: calc(var(--gutter-x) * 2);
  }
}

.node-texte {
  margin-bottom: s(5);
  max-width: $text-max-width;
  text-align: justify;
}

.node-texte-container {
  margin-bottom: s(5);
}

.titre-block {
  margin-bottom: 1rem;
}

/* -------------------------------------------------------------------------- */
/*                                    Aside                                   */
/* -------------------------------------------------------------------------- */
.node-illustration-xs {
  border-radius: 0.8rem;
  overflow: hidden;
}

.node-illustration {
  margin: 0;
  position: relative;
  @include media-breakpoint-down(lg) {
    margin-bottom: 1.5rem;
  }
  // @include hors-container('r');
  a {
    display: block;
  }
}

.illustration-credits-container {
  position: relative;
}

.illustration-credits {
  position: absolute;
  pointer-events: none;
  color: #fff;
  bottom: 1rem;
  left: 1rem;
  z-index: 10;
  right: 1rem;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  font-size: 0.85em;
  line-height: 1.2;
}

.has-memoire-videos-top {
  .node-illustration-xs,
  .node-illustration {
    display: none !important;
  }
}

.video-block-container .video-block {
  background-color: $video-block-bg;
}

@mixin encadre-style {
  @include padding(2rem);
  & > :first-child {
    margin-top: 0;
  }
  & > :last-child {
    margin-bottom: 0;
  }
  a:not(.bouton) {
    text-decoration: underline;
    color: inherit;
    text-decoration-color: currentColor;
  }
}

.video-description-container,
.image-description-container {
  @include encadre-style;
  background: var(--site-color);
  color: #fff;
  margin-bottom: var(--gutter-x, 1rem);
}

/* --------------------------------- ENCADRE -------------------------------- */
.encadre {
  @include encadre-style;
  margin: 2rem 0;
  background: $gray-200;
  border-radius: $border-radius-lg;
}

.placeholder-bg {
  background-color: $lazy-placeholder-bg;
}

/* -------------------------------------------------------------------------- */
/*                             MODULES SPECIFIQUES                            */
/* -------------------------------------------------------------------------- */

.hide-breadcrumb nav[aria-label="breadcrumb"],
.hide-header .node-header {
  display: none;
}
