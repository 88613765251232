.strate-slider-accueil {
  @include media-breakpoint-up(md) {
    margin-right: calc(-1 * var(--container-gutter-x));
  }
}

.slider-accueil {
  a {
    text-decoration: none;
  }

  .slider-accueil-titre {
    font-weight: bold;
  }

  @include media-breakpoint-up(lg) {
    .slider-accueil-texte {
      &:before {
        content: "";
        z-index: 10;
        bottom: 0;
        right: 100%;
        margin-right: -1px;
        border-bottom: 3.5rem solid #fff;
        border-left: 3.5rem solid transparent;
        display: block;
        position: absolute;
      }
    }
  }

  .slider-accueil-texte {
    display: flex;
    align-items: center;
    position: relative;
    padding: var(--gutter-x, 1.2rem) 0;
    @include media-breakpoint-up(md) {
      padding: var(--gutter-x, 1.2rem);
    }

    .slider-accueil-resume {
      &:after {
        // content: "..";
      }
    }

    // SI une couleur
    // de fond est définie
    &.couleur-fond {
      color: #fff;

      &:before {
        border-bottom-color: var(--site-color);
      }
    }
  }

  // IMAGE, VIDEO
  .slider-accueil-img {
    position: relative;

    iframe,
    video,
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.slider-accueil-container.not-home .slider-accueil {
  @include media-breakpoint-up(md) {
    box-shadow: 0 2rem 3.5rem -2rem rgba(0, 0, 0, 0.2);
  }
  z-index: 10;
}

.slider-accueil-container.not-home {
  box-shadow: 0 -2rem 3.5rem -3rem rgba(0, 0, 0, 0.08);
  z-index: 10;
}

// .slider-accueil-container.is-home {
//   overflow: visible;
// }

/* -------------------------------------------------------------------------- */
/*                              COLONNAGE SLIDER                              */
/* -------------------------------------------------------------------------- */

@mixin afficheSliderImg_1x1() {
  .slider-accueil-img-1x1 {
    display: none;
  }
  .slider-accueil-img-3x2 {
    display: block;
  }
}

@mixin afficheSliderImg_3x2() {
  .slider-accueil-img-1x1 {
    display: block;
  }
  .slider-accueil-img-3x2 {
    display: none;
  }
}

// SLIDER : colonnage texte et image + ratio
.slider-accueil .swiper-slide-prev a {
  opacity: 0 !important;
  pointer-events: none;
}

.slider-accueil a {
  display: grid;
  opacity: 1;
  transition: 0.5s opacity;

  .slider-accueil-img {
    border-radius: $border-radius-lg;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      border-radius: 0;
    }
    @include min-ratio("1x1");
    @include afficheSliderImg_1x1;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up($bp-grid-4fr) {
    .slider-accueil-img {
      @include min-ratio("3x4");
      @include afficheSliderImg_3x2;
    }
  }

  @include media-breakpoint-up($bp-grid-6fr) {
    grid-template-columns: 3fr 2fr;
    .slider-accueil-img {
      @include min-ratio("3x2");
      @include afficheSliderImg_3x2;
    }
  }
}

// FIX DANS layout grid
.slider-accueil-container {
  width: 100%;
  position: relative;
}

@include media-breakpoint-up(md) {
  .slider-accueil-container.is-home .slider-accueil {
    overflow: visible;
  }
}

// OVERFLOW
.slider-accueil {
  .swiper-slide {
    width: 100%;

    @include media-breakpoint-up($bp-grid-6fr) {
      width: calc(var(--js-container-width, 100%) * 5 / 6);
    }
  }
}

.slider-accueil-badges {
  display: none;
}

.slider-accueil-badges {
  position: absolute;
  z-index: 10;
  top: 0;

  @include media-breakpoint-up($bp-grid-6fr) {
    display: block;
    left: calc(var(--js-container-width, 100%) * 5 / 6);
    width: calc(var(--js-container-width, 100%) * 1 / 6);
  }

  .slider-accueil-container.not-home + .slider-accueil-badges:before {
    display: none !important;
  }
}

/* -------------------------------------------------------------------------- */
/*                                     NAV                                    */
/* -------------------------------------------------------------------------- */

.slider-accueil-container .slider-accueil-nav {
  // position: absolute;
  z-index: 10;
  margin-top: 0.5rem;
}
