
.filtres-container {
  margin-bottom: 2rem;
}

/* -------------------------------------------------------------------------- */
/*                              Filtres niveau 1                              */
/* -------------------------------------------------------------------------- */
.filtres-niveau-1 {
  display: flex;
  flex-wrap: wrap;
  .btn {
    margin:0 .3rem .3rem 0
  }
}

/* -------------------------------------------------------------------------- */
/*                               Autres filtres                               */
/* -------------------------------------------------------------------------- */
.filtres-supplementaires {
  width: 100%;
  margin-top: .25rem;
  margin-bottom: 1rem;
}
.plus-de-criteres {
  font-weight: bold;
  padding:0;
  border:none;
  color: $text-muted;
  text-transform: uppercase;
  font-size: .8rem;
  background: transparent;
  position: relative;
  
  &.active:before {
    background:$danger;
    display: block;
    content: "";
    width: 13px;
    height: 13px;
    position: absolute;
    left: -7px;
    bottom:0;
    border: 2px solid #fff;
    border-radius: 50%;
  }

  &[aria-expanded="true"]{
    color: var(--site-color);
  }
}

/* -------------------------------------------------------------------------- */
/*                          CRITERES supplementaires                          */
/* -------------------------------------------------------------------------- */
.criteres-container {
  font-size: .8rem;
  //padding:0 1rem;
  line-height: $line-height-sm;
  margin-bottom: 5rem;
}


// LISTE DE thèmes
.criteres-titre {
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: s(1);
  margin-bottom: s(1);
  border-bottom: 1px solid;
}


.criteres-block-full {
  grid-column-end: -1;
}

.criteres-filtres {
  display: flex;
  flex-wrap: wrap;
  @include media-breakpoint-up(md){
    flex-direction: column;
  }
}

.criteres-filtres.criteres-filtres-inline {
  flex-direction: row;
}

.lien-filtre {
  text-decoration: none;
  margin:.1rem  1rem .1rem 0;
  color: $text-muted;
  display: inline-block;
  font-weight: 400;

  &::first-letter{
    text-transform: uppercase;
  }

  &:hover, 
  &:focus,
  &.active {
    font-weight: bold;
    color: var(--site-color);
  }

}

/* -------------------------------------------------------------------------- */
/*                               Bouton imprimer                              */
/* -------------------------------------------------------------------------- */
.imprimer-liste{
  display: none;

  .liste-imprimable & {
    display: inline-flex;
  }
}


.messages-liste-vide {
  @include margin-top(5rem);
  @include margin-bottom(5rem);
  @include font-size(2.1rem);
  text-align: center;
  color: $gray-500;
}