.main-menu  {
  display: flex;
  list-style: none;
  padding:0;
  margin:0;
  line-height: 1;
  @include rfs(1.45rem);
  white-space: nowrap;
  a {
    
    text-decoration: none;
    @include margin-left(1.7rem);
    font-weight: bold;
    display: block;
    position: relative;
    &::first-letter{
      text-transform: uppercase;
    }

    &:before {
      width: .8rem;
      height: .8rem;
      position: absolute;
      top: 115%;
      margin-bottom: -1rem;
      border-radius: 50%;
      left: 50%;
      content:"";
      background:$gray-300;
      transition:transform .2s cubic-bezier(0.44,0.08,0.77,1.77);
      transform:translateX(-50%) scale(0);
    }

  }

  a.is-hover,
  a:focus,
  a:hover{
    &:before {
      transform:translateX(-50%) scale(1);
    }
  }

  a.is-hover,
  a.active:not(.not-hover){
    &:before {
      background-color: currentColor;
      transform:translateX(-50%) scale(1);
    }
  }


  // POSITIONNE LE LIEN CICLIC SUR logo
  // pour position du sous-menu
  // .pole-ciclic {
  //   opacity: 0;
  //   left: 0;
  //   position: absolute;
  //   pointer-events: none;
  //   margin-top: -1rem;
  // }

  a[class*="pole-"][class*="-info"]{
    font-weight: normal;
  }

}
