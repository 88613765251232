$enable-weform-default: true !default;
$form-max-width: 80ch !default;
$form-grid-template-columns: var(--form-grid-template-colums, 1fr 1fr )  !default;
$form-grid-gap: var(--gutter-x) !default;
$form-bp: sm !default;

@mixin webform-default($template-columns: $form-grid-template-columns, $gap: $form-grid-gap, $max-width: $form-max-width, $bp: $form-bp, $legacy:false) {
  margin-bottom :4rem;
  max-width: $max-width;

  

  // Layout en grid pour fieldset
  // et formulaire sans fieldset
  &, 
  & .fieldset-wrapper{
    display: grid;
    grid-gap: $gap;

    @include media-breakpoint-up($form-bp){
      grid-template-columns: $template-columns ;
    }

  }
  & .fieldset-wrapper {
    grid-column: 1 / -1;
  }


  &, 
  fieldset div:not([class]){
    div[id*=ajax-wrapper] {
        display:contents;
        grid-column: 1 / -1;

    }
  }

  // Elements sur deux colonnes
  & , 
  & fieldset{
    .grid-full,
    fieldset,
    [class*=form-actions] ,
    [class*=form-type-multiple-file] ,
    [class*=form-type-file] ,
    [class*=form-type-markup] ,
    [class*=form-type-select] ,
    [class*=form-type-textarea] ,
    [class*=form-type-checkboxes] ,
    [class*=form-type-radios] {
        grid-column: 1 / -1;
    }
  }

  .captcha  {
    grid-column-start:1; 
    font-size: .8rem;
    summary {
      font-weight: bold;
      line-height: $line-height-sm;
    }
    .form-item-captcha-response {
      font-size: 1rem;
      margin-top: .4rem;
    }
    .description {
      margin-top: .1rem;
      font-size: .8rem;
      line-height: $line-height-sm;
    }
  }

  // DRUPAL 7 SUPPORT
  @if $legacy {

    & > div > .col-xs-12 {
      grid-column: 1 / -1;
    }

    & > div:not([class]){
      display:contents;
      grid-column: 1 / -1;
    }

    & .form-item {
      margin-bottom: 0;
    }

    & , 
    & fieldset{
      [class*=field-type-list-text] ,
      [class*=form-type-checkbox] ,
      [class*=field-type-text-long],
      [class*=form-type-select] 
      {
        grid-column: 1 / -1;
      }
    }

  }


}

// APPLIQUE les styles par défaut à tous les webforms
@if $enable-weform-default{
  .ssks-submission-form,
  .webform-submission-form {
    @include webform-default();
  }
}