    /*------------------------------------------------
    :: AFFICHAGE LIstes de dates dans un node
    --------------------------------------------------*/
    /*liste*/
    .liste-dates-element {
      display: flex;
      line-height: $line-height-sm;
      align-items: center;
      margin-bottom: 1rem;
    }
    
    .liste-date-element-ajout {
        margin-right: .5rem;
    }
    // .liste-dates {
    //   border-top: .15rem dotted $gray-200;
    //   padding-top:1rem;
    // }


    /*map*/
    // .node-actualites-dates .google-maps{height:500px;background:#fff;}
    // .bubble-addresse strong{color:#000;display:inline-block;font-weight:normal;margin-bottom:5px;font-size:121%;line-height:1.3em;}
    // .bubble-addresse a{margin-top:1em;}

    // a.ajouter-agenda {width: 24px; height: 16px;display:inline-block;margin:-4px 2px 0 0;vertical-align:middle;background-position: 0 -787px;}
    // .bubble-date {margin:3px 0;}