.mceContentBody {
  word-break: break-word;

  h2,
  h3,
  h4 {
    margin-top: 1.4em;
    margin-bottom: 0.8em;
    font-weight: bold;
  }

  // FigCaption
  > figure figcaption {
    margin: s(2) 0 0 s(3);
    font-size: 85%;
  }

  > *:first-child {
    margin-top: 0;
  }
  > ol:last-child,
  > ul:last-child,
  > p:last-child {
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: $border-radius-lg;
  }
}

figure img {
  width: 100%;
}

// .image-gauche,
// .image-droite {border-radius: $border-radius;}

.image-gauche {
  float: left;
  margin: 0.1em 1em 1em 0 !important;
}
.image-droite {
  float: right;
  margin: 0.1em 0 1em 1em !important;
}
.node-texte img[style*="float: left;"] {
  max-width: 30%;
}
.clearFloat {
  clear: both;
}

// CHAPO
.chapo {
  font-size: 130%;
  line-height: 1.35;
  font-weight: bold;
  // span {
  //   all:unset !important;
  // }
}

// ACCROCHE
.accroche {
  display: block;
  span {
    all: unset !important;
  }
  max-width: 90%;
  font-size: 130%;
  margin: 2.6rem auto;
  padding: 1.5rem 0;
  border-top: 2px solid;
  border-bottom: 2px solid;
  line-height: $headings-line-height;
}

/* -------------------------------------------------------------------------- */
/*                               lien classique                               */
/* -------------------------------------------------------------------------- */
.mceContentBody {
  a:not(.bouton) {
    text-decoration-color: var(--site-color);
    color: var(--site-color);
  }

  a:not(.bouton):focus,
  a:not(.bouton):hover {
    background: var(--site-color);
    color: #fff;
    text-decoration: none;
    // box-shadow: 0 0 0 .15rem var(--site-color);
    // border-radius: 2px;
  }
}

.mceContentBody span.bouton a,
.mceContentBody a.bouton {
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.4em 1.5em;
  border: $border-width solid;
  border-radius: 25rem;
  display: inline-block;

  &:focus,
  &:hover {
    background-color: $body-color;
    color: #fff;
    border-radius: 25rem;
    box-shadow: none;
  }
}

// TABLEAU
.mceContentBody table {
  line-height: $line-height-sm;
  vertical-align: top;

  td:first-child img {
    border-radius: $border-radius;
    overflow: hidden;
  }

  h2,
  h3,
  h4,
  p,
  ul,
  ol {
    margin: 0;

    // &:last-child {
    //   margin:0;
    // }
  }
}

/*  ------------------------------------------------
:: Element separe et HASHTAG
--------------------------------------------------*/
.hashtag:before {
  content: "#";
}

.element-separe:after {
  content: " | ";
}
.element-separe-underscore:after {
  content: " _ ";
}
.element-separe-tiret:after {
  content: " – ";
}
.element-separe-tiret-lg:after {
  content: " — ";
}
.element-separe-tiret-sm:after {
  content: " - ";
}
.element-separe-point:after {
  content: " • ";
}
.element-separe-petit-point:after {
  content: " · ";
}

.element-separe-virgule:after {
  content: ", ";
}
.element-separe-slash:after {
  content: " / ";
}
.element-separe-espace:after {
  content: " ";
}

.element-separe-petit-point:last-child:after,
.element-separe-point:last-child:after,
.element-separe:last-child:after,
.element-separe-tiret:last-child:after,
.element-separe-tiret-lg:last-child:after,
.element-separe-tiret-sm:last-child:after,
.element-separe-underscore:last-child:after,
.element-separe-virgule:last-child:after,
.element-separe-slash:last-child:after,
.element-separe-espace:last-child:after {
  content: none;
}

.element-separe-dot-light {
  display: inline-block;

  &:not(:last-child) {
    &:after {
      content: "•";
      display: inline-block;
      margin: 0 s(2);
      color: $gray-400;
      font-size: 1rem;
      font-weight: bold;
    }
  }
}

// ANNULE COLONNAGE MEMOIRE IMG
.textRightFrame,
.textLeftFrame,
.textImageGauche,
.textImageDroite {
  width: 80%;
  display: block;
  margin: 2.5rem auto;
  max-width: 50rem !important;
  overflow: hidden;
}
