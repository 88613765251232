.recherche-commune-form {
  max-width: 25em;
  @include shadowBlock(md, 3rem);
  .form-item {
    margin-bottom: 0;
  }

}

.page-node-667 .recherche-commune-form{
  margin-top: -4rem;
  .toutes-les-villes {
    display: none !important;
  }
}