.block-agenda-widget {
  grid-column: span 2;
  line-height: $line-height-sm;
}

.block-agenda-widget-date-titre {
  margin: 1rem 0 0.25rem;
  font-weight: 500;
  text-transform: uppercase;
}
.block-agenda-widget-date {
  margin-bottom: 0.1rem;
}

.block-agenda-widget-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow: hidden;

  .block-agenda-widget-titre {
    img {
      width: 3rem;
    }
  }

  .block-agenda-widget-liste {
    mask-image: linear-gradient(
      black 60%,
      rgba(0, 0, 0, 0.8) 70%,
      transparent 90%
    );
    overflow: hidden;
  }
}

.block-agenda-widget .btn {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}
