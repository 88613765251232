.search-info {
  display: none !important;
}

.search-results {
  padding: 0;
  margin:0 0 4rem;
}

.search-result {
  list-style: none;
  margin:2.5rem 0 ;
  padding: 0;
  
  .title a {
   text-decoration: underline;
  }

  .title a:focus,
  .title a:hover {
    background:$body-color;
    color:#fff;
    text-decoration: none;
   }
}
