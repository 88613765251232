.swiper-button-next ,
.swiper-button-prev  {
  color:#fff
}

.swiper-pagination-bullet  {
  width: .7rem;
  height: .7rem;
}

.slider-photos img {
  aspect-ratio: #{'3 / 2'};
  object-fit: cover;
}


.swiper-pagination-bullet {
  background-color: #fff;
}
.swiper-pagination-bullet-active {
  background-color: #fff;
  mix-blend-mode: normal;

}

.node-galerie-photo   .swiper-button-disabled{
  display: none;
}