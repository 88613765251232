
/* -------------------------------------------------------------------------- */
/*                                HEADER MOBILE                               */
/* -------------------------------------------------------------------------- */
.website-header-mobile {
  align-items: center;
  justify-content: center;
  padding:1.8rem 4rem 0 ;
  margin-bottom: 2rem;
  position: relative;
}

.menu-mobile-bouton {
  margin-right: 1rem;
  width: 1em;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) translateY(.5rem);
  padding:0 ;
  background: transparent;
  border: none;
}

.logo-mobile {
  text-align: center;
  img {
    // width: 80%;
    max-width: 100%;
    height: 4rem;
  }
}

/* -------------------------------------------------------------------------- */
/*                               HEADER desktop                               */
/* -------------------------------------------------------------------------- */

.website-header-desktop {
  @include padding-top(1rem);
  @include margin-bottom(2.5rem);
  position: relative;

  .has-sous-menu & {
    @include margin-bottom(7rem);
  }
}


.website-header-menus{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.logo-desktop {
  text-align: left;
  img {
    width: 100%;
    object-position: left bottom;
    max-width: 13rem;
    height: 7rem;
    object-fit: contain;
    @include media-breakpoint-up(xl){
      max-width: 18rem;
    }
  }
}


.main-menu-container {
  margin-top: auto;
}


// CACHE LE FIL D'ARIANE
.hide-breadcrumb .fil-ariane,
.hide-fil-ariane .fil-ariane{
  display: none !important;
}