.main-menu-enfants {
    line-height: $line-height-sm;
    @include margin(4rem 0 7rem )
}

.main-menu-enfants > ul > li {
    border-bottom: 0.1rem solid $gray-200;
    margin: 1rem 0;
    padding: 0 0 0.8rem 0;
    &:last-child {
      border-bottom: none;
    }
}

// NIVEAU 3
.enfants-niveau-3 {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0;
}

.main-menu-enfants-icone {
    background: $gray-200;
    border-radius: $border-radius;
    display: block;
    margin-right: 0.5rem;
    padding: 0.5rem;
    font-size: 0.8em;
    margin-top: -0.30rem;
    a:focus &,
    a:hover & {
      background: $gray-800;
      color:#fff;
      transition:.2s;
    }
}

// NIVEAU 4
.enfants-niveau-4 {
  color: $gray-600;
  &:hover,
  &:focus{
    color: $body-color;
  }
}

.main-menu-enfants {
    ul ul {
        margin-left: 2.6rem;
        font-size: $font-size-sm;
        li {
            margin: 0 0 0.3rem;
        }
    }
}
