
.node-liens-block {
  margin-bottom: s(6);
}
.node-aside {
  .node-liens-block {
    margin-top: s(6);
  }
}

.liens-internes-liste, 
.fichiers-liste, 
.fichiers-liste{
  > div:not(:last-child) {
    margin: .7rem 0;
  }
  a {
    display: flex;
    align-items: flex-start;
  }
}

.liste-item-icone {
  margin-right: .5rem;
  margin-top: -.35rem;
  transition:.2s;
  a:hover &,
  a:focus &{
    background:var(--site-color) ;
    color: #fff;
  }
}

.fichiers-liste-item-infos {
  font-size: $font-size-sm;
  color: $text-muted;
}