.website-footer {
  background:$black;
  color: #fff;
  
  a {
    color:inherit;
  }

  img {
    max-width: 100%;
  }
  @include media-breakpoint-down($bp-grid-2fr){
    margin-top: var(--container-gutter-x);
  }
}


.footer-sites {
  display: none;
}


.website-footer-content {
  // display: grid;
  // grid-template-columns: repeat(7, 1fr);
  
  @include c_grid();
  align-items: center;

  @include media-breakpoint-down(md){
    font-size: 12px;
    padding:1.5rem 0;
  }
}

.footer-newsletter  {
  grid-column-end: -2;
}

.footer-partenaires {
  grid-column-end: -1;
  @include min-ratio('5x3');

  @include media-breakpoint-up(xl){
    @include min-ratio('1x1');
  }

  background: #fff;
  align-items: center;
  justify-content: center;
  align-items: center;
  
  div {
    a {
      margin: 1rem auto;
      max-width: 80%;
      display: block;
    }
    img {
        // width: 5rem;
        max-width: 100%;
      }
    }
  }
//OVERFLOW droite
.footer-partenaires {
  position: relative;

  &:before {
    width: var(--container-gutter-x);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    background: $gray-300;
    left: 100%;
  }
}


.footer-logo{
  @include padding(1rem 1rem 1rem 0);

  @include media-breakpoint-down(md){
    padding-top: 0;
    a {
      max-width: 10rem;
    }
  }

  a {
    display: block;
    max-width: 13rem;
  }
  img {
    width: 100%;
  }
}