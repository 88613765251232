:root{
  --container-gutter-x: #{$grid-gutter-width};
  --gutter-x: #{$grid-gutter-width};
  --gutter-y: #{$grid-gutter-width};
  --site-color: #{$black};

  
  --random-bichro-yellow:#F1D95E;
  --random-bichro-blue:#59BDE3;
  --random-bichro-purple:#8680CF;
  --random-bichro-orange:#EEAC73;
  --random-bichro-mauve:#A99AB9;
  --random-bichro-peach:#E58189;
  --random-bichro-green:#53b166;

  --grid-column-nb : 2 ;

  @include media-breakpoint-up($bp-grid-2fr) {
    --grid-column-nb : 2 ;
  }
  @include media-breakpoint-up($bp-grid-3fr) {
    --grid-column-nb : 3 ;
  }
  @include media-breakpoint-up($bp-grid-4fr) {
    --grid-column-nb : 4 ;
  }
  // @include media-breakpoint-up($bp-grid-5fr) {
  //     grid-template-columns: repeat(5, 1fr);
  // }
  @include media-breakpoint-up($bp-grid-6fr) {
    --grid-column-nb : 6 ;
  }




  @include media-breakpoint-up(md){
    --container-gutter-x: 5vw;
  }

  @include media-breakpoint-up(xl){
    --container-gutter-x: 5vw;
  }

  @include media-breakpoint-up(3xl){
    --container-gutter-x: 6vw;
  }

  @include media-breakpoint-up(4xl){
    --container-gutter-x: 7vw;
  }



  @each $breakpoint, $value in $fs-html {
    @include media-breakpoint-up($breakpoint) {
      --fs-html: #{$value};
    }
  }

  // // font-size headings, display, etc...
  // @each $breakpoint, $fs-items in $fs-list {
  //   @include media-breakpoint-up($breakpoint) {
  //     @each $fs-item, $value in $fs-items {
  //       --fs-#{$fs-item}: #{rem($value, map-get($fs-breakpoints,$breakpoint))};
  //     }
  //   }
  // }

}