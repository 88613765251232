.menu-footer {
  display: flex;
  flex-wrap: wrap;
  @include media-breakpoint-up(md){
    flex-direction: column;
  }

  >div {
    margin:.1rem 1rem .1rem 0;
  }
  a {
    @include l-hover;
    text-decoration: none;
  }
}