.memoire-liste-magazine-materiel .filtres-wrapper {
  display: flex;
  flex-direction: column;
  
  // margin-bottom: 2rem;
  @include media-breakpoint-up(lg){
    flex-direction: row;
    align-items: center;
    grid-template-columns: 1fr 1fr;
  }
  
  margin-bottom: var(--gutter-y);
  .form-type-select {
    margin-bottom: 0;
    select {
      padding-left: 2rem;
      padding-right: 3rem;
    }
  }
  
}

.memoire-liste-magazine-materiel .block-item {
  .block-type {
    font-weight: $font-weight-bold ;
  }
}

.memoire-liste-magazine-materiel {
  @include memoire-slider-date;
}